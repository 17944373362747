import { chakra } from '@chakra-ui/react'
// import { motion, isValidMotionProp } from 'motion'
import { motion } from "motion/react"

export const AnimatedBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  // shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});

export const AnimatedSpan = chakra(motion.span, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  // shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});