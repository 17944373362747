import axios from "axios"
import { rootStore } from "../store"

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL


/** for anonymous API call */
export const anonApi = axios.create({
    baseURL: API_BASE_URL + 'api/',
    timeout: 10000,
    withCredentials: true
})


/** for authenticated API call */
export const authApi = axios.create({
    baseURL: API_BASE_URL + 'api/',
    timeout: 10000,
    withCredentials: true,
    headers: {
        Authorization: `Bearer ${rootStore.userStore.apiToken}`,
    },
})

authApi.interceptors.request.use(

    async (config) => {
        config.headers![
            "Authorization"
        ] = `Bearer ${rootStore.userStore.apiToken}`
        return config
    },
    (error) => {
        return Promise.reject(error)
    },
)

export function sleep(ms = 300): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

// authApi.interceptors.response.use(async (response) => {
//     // add artificial delay for dev env
//     if (process.env.NODE_ENV === 'development') {
//         await sleep();
    //     }es
//     // console.log(response.data)
//     return response;
// });


export interface BaseResponse {
    status: "error" | "ok"
    reason?: string
}
