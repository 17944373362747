import { HStack, Icon, Span } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { RiUserFill } from "react-icons/ri"
import { useConnectedPlayers } from "../../../hooks/query"
import HelpText from "../../common/HelpText"

const ConnectedPlayers: FunctionComponent = () => {
    const { data: connected, isLoading } = useConnectedPlayers()
    
    let total = 0
    if (connected) {
        total = Object.values(connected).reduce((a, b) => a + b, 0) + 1
    }

    return (
        <HelpText tooltip={`Total players connected to the game.`} showHelpButton={false}>
            <HStack alignItems={"center"} gap="0">
                <Icon><RiUserFill /></Icon>
                <Span>{total}</Span>
            </HStack>
        </HelpText>
    )
}

export default ConnectedPlayers
