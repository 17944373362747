import { Box, HStack, Text } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { useResources } from "../../hooks/query"
import { ResourceType } from "../../types"
import Resource from "./Resource"
import Panel from "../common/Panel"

type ResourcesProps = {
    [key in ResourceType]?: number
} & {
    cost?: boolean // is a cost ? so negative if not enough
}

const Resources: FunctionComponent<ResourcesProps> = ({ cost = true, ...props }) => {
    const resources = useResources()
    return (
        <Panel visual={cost ? "badge": "smooth"} w="fit-content" alignItems={"center"} display={"flex"}>
            <HStack gap="2" wrap={"wrap"} justifyContent={"center"} alignItems={"center"} rowGap={0}>
                {props.wood !== undefined && props.wood !== 0 && (
                    <Resource name={ResourceType.WOOD} value={props.wood} variant={cost && resources.wood < props.wood ? "negative" : "positive"} animated={false} />
                )}
                {props.iron !== undefined && props.iron !== 0 && (
                    <Resource name={ResourceType.IRON} value={props.iron} variant={cost && resources.iron < props.iron ? "negative" : "positive"} animated={false} />
                )}
                {props.soldiers !== undefined && props.soldiers !== 0 && (
                    <Resource name={ResourceType.SOLDIER} value={props.soldiers} variant={cost && resources.soldiers < props.soldiers ? "negative" : "positive"} animated={false} />
                )}
                {props.workers !== undefined && props.workers !== 0 && (
                    <Resource name={ResourceType.WORKER} value={props.workers} variant={cost && resources.workers < props.workers ? "negative" : "positive"} animated={false} />
                )}
                {props.victoryPoints !== undefined && props.victoryPoints !== 0 && (
                    <Text>Gain <Resource name={ResourceType.VICTORY_POINTS} value={props.victoryPoints} animated={false} /></Text>
                )}
            </HStack>
        </Panel>
    )
}

export default Resources
