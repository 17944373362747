import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { FunctionComponent } from "react"
import { getQuest, upgradeHQ } from "../../api/game"
import { useGameId, useHQInfo } from "../../hooks/query"
import Resources from "../resources/Resources"
import { UISpendResources, useHasEnoughResources } from "../../hooks/utils"
import { ImArrowUp } from "react-icons/im"
import { HStack } from "@chakra-ui/react"
import { QuestType } from "../quests/config"
import { Button } from "../ui/button"

const HQLevelUp: FunctionComponent = () => {
    const { data: info } = useHQInfo()
    const gameId = useGameId()

    const queryClient = useQueryClient()
    // console.log(gameId)
    const mutation = useMutation({
        mutationFn: () => upgradeHQ(gameId),
        onMutate: () => {
            UISpendResources({ gameId, resources: info?.hq.upgradeCost || {} })
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ["hqInfo", gameId] }),
    })

    const enoughResources = useHasEnoughResources(info?.hq.upgradeCost || {})

    const hqInfo = useHQInfo()

    const hasActiveQuest = hqInfo?.data?.hasActiveQuest

    const { data: quest } = useQuery({
        queryKey: ["quest", gameId],
        queryFn: () => getQuest(gameId),
        enabled: !!hasActiveQuest,
    })
    const currentQuest = quest?.name

    const disableHQUpgrade = [QuestType.WOODCUTTER, QuestType.WOODCUTTER_UPGRADE, QuestType.MINE].includes(currentQuest as QuestType)


    return (
        <HStack wrap="wrap" align={"center"} justify={"center"}>
            <Resources {...info?.hq.upgradeCost} />
            <Button
                onClick={() => {
                    console.log("pre mutate")
                    console.log(mutation)
                    mutation.mutate()
                }}
                disabled={!enoughResources || disableHQUpgrade}
                aria-label="Upgrade HQ"
            >
                <ImArrowUp />
                Upgrade
            </Button>
        </HStack>
    )
}

export default HQLevelUp
