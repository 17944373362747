import { defineSlotRecipe } from "@chakra-ui/react";


export const tabsSlotRecipe = defineSlotRecipe({
    slots: ['root', 'list', 'trigger', 'content', 'indicator'],

    variants: {


        variant: {
            main: {
                trigger: {
                    fontSize: '1.2rem',
                    display: 'flex',
                    border: '2px solid',
                    borderColor: 'transparent',
                    bg: "orange.500",
                    borderRadius: 'none',
                    minWidth: ['auto', 'auto', '150px'],
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 2,
                    _selected: {
                        bg: "orange.200",
                        color: "black",
                        borderBottom: '0',
                        borderColor: 'red.700',
                    },
                    _hover: {
                        bg: "orange.200",
                        color: "black",
                    },
                    width: '100%',
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                },
                list: {
                    display: "flex",
                    justifyContent: "center"
                },
                content: {
                    border: '2px solid',
                    borderColor: 'orange.800',
                    boxShadow: 'md',
                }
            },
            chat: {
                trigger: {
                    display: 'flex',
                    border: '1px solid',
                    borderBottom: 0,
                    borderColor: "orange.700",
                    bg: "orange.500",
                    borderRadius: 'none',
                    boxShadow: 'md',
                    minWidth: ['auto', 'auto', '100px'],
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 0,
                    _hover: {
                        bg: "orange.400",
                        color: "black",
                    },
                    _selected: {
                        bg: "orange.200",
                        color: "black",
                        borderBottom: '0',
                        borderColor: 'red.700',
                    },
                },
                list: {
                    display: "flex",
                },
                content: {
                    border: '1px solid',
                    borderColor: 'orange.800',
                    boxShadow: 'md',
                    padding: "1"
                },
            }
        }
    }
})