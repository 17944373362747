import { Box, Icon, Text } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { observer } from "mobx-react-lite"
import { FunctionComponent } from "react"
import { RiShieldUserFill } from "react-icons/ri"
import { useNavigate } from "react-router-dom"
import { EventType, Factions } from "../../../types"
import { useStore } from "../../../store"
import { useGameId, usePlayer } from "../../../hooks/query"
import { useIsDebug } from "../../../hooks/utils"
import { debugAction } from "../../../api/game"
import Link from "../../common/Link"
import {
    MenuContent,
    MenuItem,
    MenuItemCommand,
    MenuRoot,
    MenuSeparator,
    MenuTrigger,
} from "@/components/ui/menu"
// import { getTileInfo } from "../../api/world"

const ProfileButton: FunctionComponent = observer(() => {
    const { userStore, socketStore } = useStore()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const player = usePlayer()
    const showDebug = useIsDebug()
    const gameId = useGameId()

    const debug = useMutation({
        mutationFn: (params: any) => debugAction(gameId, params),
        onSuccess: () => queryClient.invalidateQueries(),
    })

    const changeUser = (faction: Factions) => {
        debug.mutate(
            { name: "change_user", faction },
            {
                onSuccess: (data) => {
                    userStore.setApiToken(data.token)
                    queryClient.invalidateQueries()
                },
            }
        )
    }

    const overflowServer = () => {
        for (let x = 0; x < 50; x++) {
            for (let y = 0; y < 50; y++) {
                // getTileInfo(x, y)
            }
        }
    }

    return (
        <>
            {!userStore.isAuthenticated && <Link to="/login">Login</Link>}

            {userStore.isAuthenticated && player && (
                <MenuRoot variant="solid" size="md">

                    <MenuTrigger
                        bgColor="orange.500"
                        width="50px"
                        height="45px"
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        borderRadius={"sm"}
                        position="relative"
                        cursor="pointer"
                    >
                        <Icon
                            color={player?.faction}
                            width="100%"
                            height="100%"
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                        >
                            <RiShieldUserFill />
                        </Icon>
                        {player?.hasPendingInvitation && (
                            <Box
                                display={"inline-block"}
                                borderRadius="50%"
                                width="8px"
                                height="8px"
                                bgColor="red.800"
                                position="absolute"
                                top="0px"
                                right="0"
                            ></Box>
                        )}
                    </MenuTrigger>
                    <MenuContent>
                        <MenuItem
                            value="menu-games"
                            onClick={() => {
                                navigate("/games")
                            }}
                        >
                            Games
                        </MenuItem>
                        <MenuItem
                            value="menu-group"
                            onClick={() => {
                                navigate("/group")
                            }}
                        >
                            Group
                            {player?.hasPendingInvitation && (
                                <Box
                                    display={"inline-block"}
                                    borderRadius="50%"
                                    width="8px"
                                    height="8px"
                                    bgColor="red.800"
                                    position="absolute"
                                    top="5px"
                                    right="0"
                                ></Box>
                            )}
                        </MenuItem>
                        <MenuItem
                            value="menu-settings"
                            onClick={() => {
                                navigate("/settings")
                            }}
                        >
                            Settings
                        </MenuItem>
                        <MenuItem
                            value="menu-register"
                            onClick={() => {
                                navigate("/register")
                            }}
                        >
                            {userStore.player?.temporary ? "Register" : "Account"}
                        </MenuItem>
                        {(!userStore.player?.temporary || showDebug) && (
                            <MenuItem
                                value="menu-logout"
                                onClick={() => {
                                    if (!userStore.player?.temporary || confirm("Are you sure you want to logout ? As you're not registered, you'll lose your progress.")) {
                                        userStore.logout()
                                    }
                                }}
                            >
                                Logout
                            </MenuItem>
                        )}
                        {showDebug && (
                            <>
                                <MenuSeparator />
                                <MenuItem
                                    value="debug"
                                    onClick={() => {
                                        debug.mutate({ name: "debug" })
                                    }}
                                >
                                    Debug (server call)
                                </MenuItem>
                                <MenuItem
                                    value="debug-overflow"
                                    onClick={() => {
                                        overflowServer()
                                    }}
                                >
                                    Debug (overflow)
                                </MenuItem>
                                <MenuItem
                                    value="debug-fulljs"
                                    onClick={() => {
                                        socketStore.debug_createNotifications()
                                    }}
                                >
                                    Debug (full js)
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        debug.mutate({ name: "resources" })
                                    }}
                                >
                                    Give resources
                                </MenuItem>
                                <MenuItem
                                    value="debug-yellow"
                                    onClick={() => {
                                        changeUser(Factions.YELLOW)
                                    }}
                                >
                                    <Text color="yellow.600">Be yellow</Text>
                                </MenuItem>
                                <MenuItem
                                    value="debug-green"
                                    onClick={() => {
                                        changeUser(Factions.GREEN)
                                    }}
                                >
                                    <Text color="green.600">Be green</Text>
                                </MenuItem>
                                <MenuItem
                                    value="debug-blue"
                                    onClick={() => {
                                        changeUser(Factions.BLUE)
                                    }}
                                >
                                    <Text color="blue.600">Be blue</Text>
                                </MenuItem>
                                <MenuItem
                                    value="debug-red"
                                    onClick={() => {
                                        changeUser(Factions.RED)
                                    }}
                                >
                                    <Text color="red.600">Be red</Text>
                                </MenuItem>
                                <MenuSeparator />
                                <MenuItem
                                    value="debug-event-upgrade"
                                    onClick={() => {
                                        debug.mutate({ name: "event", event: EventType.UPGRADE_COST_RANDOMIZER })
                                    }}
                                >
                                    Event : {EventType.UPGRADE_COST_RANDOMIZER}
                                </MenuItem>
                                <MenuItem
                                    value="debug-event-trade"
                                    onClick={() => {
                                        debug.mutate({ name: "event", event: EventType.TRADE_ROUTE })
                                    }}
                                >
                                    Event : {EventType.TRADE_ROUTE}
                                </MenuItem>
                                <MenuItem
                                    value="debug-event-lowtide"
                                    onClick={() => {
                                        debug.mutate({ name: "event", event: EventType.LOW_TIDE })
                                    }}
                                >
                                    Event : {EventType.LOW_TIDE}
                                </MenuItem>
                                <MenuItem
                                    value="debug-event-storage"
                                    onClick={() => {
                                        debug.mutate({ name: "event", event: EventType.STORAGE_BOOST })
                                    }}
                                >
                                    Event : {EventType.STORAGE_BOOST}
                                </MenuItem>
                                <MenuItem
                                    value="debug-event-earthquake"
                                    onClick={() => {
                                        debug.mutate({ name: "event", event: EventType.EARTHQUAKE })
                                    }}
                                >
                                    Event : {EventType.EARTHQUAKE}
                                </MenuItem>
                                <MenuItem
                                    value="debug-event-neutral"
                                    onClick={() => {
                                        debug.mutate({ name: "event", event: EventType.NEUTRAL_INVASIONS })
                                    }}
                                >
                                    Event : {EventType.NEUTRAL_INVASIONS}
                                </MenuItem>
                                <MenuItem
                                    value="debug-event-frenzy"
                                    onClick={() => {
                                        debug.mutate({ name: "event", event: EventType.FRENZY })
                                    }}
                                >
                                    Event : {EventType.FRENZY}
                                </MenuItem>
                                <MenuItem
                                    value="debug-event-losing"
                                    onClick={() => {
                                        debug.mutate({ name: "event", event: EventType.LOSING_FACTIONS_SOLDIER_BONUS })
                                    }}
                                >
                                    Event : {EventType.LOSING_FACTIONS_SOLDIER_BONUS}
                                </MenuItem>
                                <MenuItem
                                    value="debug-event-winter"
                                    onClick={() => {
                                        debug.mutate({ name: "event", event: EventType.WINTER })
                                    }}
                                >
                                    Event : {EventType.WINTER}
                                </MenuItem>
                                <MenuItem
                                    value="debug-event-summer"
                                    onClick={() => {
                                        debug.mutate({ name: "event", event: EventType.SUMMER })
                                    }}
                                >
                                    Event : {EventType.SUMMER}
                                </MenuItem>
                                <MenuItem
                                    value="debug-event-spring"
                                    onClick={() => {
                                        debug.mutate({ name: "event", event: EventType.SPRING })
                                    }}
                                >
                                    Event : {EventType.SPRING}
                                </MenuItem>
                                <MenuItem
                                    value="debug-event-fall"
                                    onClick={() => {
                                        debug.mutate({ name: "event", event: EventType.FALL })
                                    }}
                                >
                                    Event : {EventType.FALL}
                                </MenuItem>
                            </>
                        )}
                    </MenuContent>
                </MenuRoot>
            )}
        </>
    )
})

export default ProfileButton
