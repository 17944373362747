
import { Box, HStack, Heading, Icon, Stack, Text } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { Event as EventInterface } from "../../api/event"
import DateComponent from "../common/DateComponent"
import { IoIosFlash } from "react-icons/io"
import { useTranslation } from "react-i18next"
import { EventType } from "../../types"
import { FaInfinity } from "react-icons/fa6";
import { Tooltip } from "../ui/tooltip"

interface EventProps {
    event: EventInterface
}


function getInterpolationValues(event: EventInterface) {
    switch (event.event_type) {
        case EventType.SPRING:
            return {
                bonus: event.effects[0].bonus,
            }
        case EventType.SUMMER:
            return {
                bonus: event.effects[0].bonus,
            }
        case EventType.FALL:
            return {
                bonus: event.effects[0].bonus,
            }
        case EventType.FRENZY:
            return {
                bonus: event.effects[0].bonus,
            }
        case EventType.WINTER:
            return {
                bonus: event.faction_effects[0].bonus,
            }
        case EventType.STORAGE_BOOST:
            return {
                bonus: event.effects[0].bonus,
            }
        default:
            return {}
    }
}


const Event: FunctionComponent<EventProps> = ({ event }) => {

    const { t } = useTranslation()
    return (
        <Box border="1px solid" borderColor="orange.700" borderRadius="md" p="2" boxShadow="md">
            <HStack justify="space-between" wrap="wrap">
                <Text fontSize={"md"}>{t(`events.${event?.event_type}.title`)}</Text>
                <Box minWidth={"7.5rem"} fontSize={"xs"}>
                    {event.flash && <Tooltip content="Flash event, no duration"><Icon color="orange.800"><IoIosFlash /></Icon></Tooltip>}
                    {event.permanent && <Tooltip content="Permanent project"><Icon color="orange.800"><FaInfinity /></Icon></Tooltip>}
                    <DateComponent date={event.start} />{event.end && <> → <DateComponent date={event?.end} /></>}
                </Box>
            </HStack>

            <Text fontSize={"sm"}>{t(`events.${event?.event_type}.description`, getInterpolationValues(event))}</Text>
        </Box>
    )
}

export default Event
