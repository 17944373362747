import {
    Badge,
    Box,
    Center,
    HStack,
    Input,
    Text,
} from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent, useRef, useEffect, Fragment } from "react"
import { useStore } from "../../store"
import MessageComponent from "./Message"
import { useIsMobile, useIsVisible, usePrevious } from "../../hooks/utils"
import { useNavigate } from "react-router-dom"
import Loading from "../common/Loading"
import { useGameId } from "../../hooks/query"
import { Button } from "../ui/button"

interface ChannelProps {
    channelId: string
}
const Channel: FunctionComponent<ChannelProps> = observer(({ channelId }) => {
    const { chatStore, mapStore } = useStore()
    const gameId = useGameId()
    const channel = chatStore.getChannel(channelId)
    const ref = useRef<HTMLInputElement>(null)
    const boxRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const isMobile = useIsMobile()

    const sendMessage = (e: React.FormEvent) => {
        e.preventDefault()
        chatStore.sendMessage(channelId, ref.current!.value)
        ref.current!.value = ""
        if (boxRef.current) boxRef.current.scrollTo(0, boxRef.current.scrollHeight)
    }

    // scroll when new message is added
    useEffect(() => {
        // scroll to bottom if we're already at the bottom
        if (
            boxRef.current &&
            boxRef.current.scrollTop + boxRef.current.clientHeight + 100 > boxRef.current.scrollHeight
        ) {
            boxRef.current.scrollTo(0, boxRef.current.scrollHeight)
        }
    }, [channel?.messages.length])

    // scroll to bottom at the start
    useEffect(() => {
        if (channel?.loaded && boxRef.current) {
            boxRef.current.scrollTo(0, boxRef.current.scrollHeight)
        }
    }, [channel?.loaded])

    const handleMessageClick = (e: any) => {
        if (e.target.dataset?.x && e.target.dataset?.y) {
            mapStore.setSelectedTile(parseInt(e.target.dataset!.x), parseInt(e.target.dataset!.y))
            navigate(`/play/${gameId}/world`)
        }
    }

    const isVisible = useIsVisible(boxRef)
    const prevIsVisible = usePrevious(isVisible)

    useEffect(() => {
        if (!isVisible && prevIsVisible) {
            chatStore.markAsRead(channelId, true)
        }
        if (isVisible && boxRef.current) {
            boxRef.current.scrollTo(0, boxRef.current.scrollHeight)
            if (!isMobile) setTimeout(() => ref.current?.focus(), 1)
        }
    }, [isVisible, prevIsVisible])

    return (
        <Box>
            <Box maxHeight={400} height={400} overflowY={"scroll"} ref={boxRef} onClick={handleMessageClick}>
                {!channel?.loaded && (
                    <Center height="full">
                        <Loading />
                    </Center>
                )}
                {channel?.messages.map((message, i) => {
                    const isLastRead = (i !== channel.messages.length - 1) && channel?.lastReadMessage === message.id
                    return (
                        <Fragment key={message.id}>
                            <MessageComponent
                                {...message}
                                channelId={channelId}
                                isLast={i === channel.messages.length - 1}
                            />
                            {isLastRead && (
                                <HStack paddingX="2">
                                    <Box width="100%" height="1px" bgColor="white"></Box>
                                    <Text fontSize="xs">new</Text>
                                    <Box width="100%" height="1px" bgColor="white"></Box>
                                </HStack>
                            )}
                        </Fragment>
                    )
                })}
            </Box>

            <form onSubmit={sendMessage}>
                <HStack gap="0">
                    <Badge as="span" whiteSpace={"nowrap"} mr="2" fontSize="sm" colorPalette="teal" onClick={(e) => ref.current?.focus()}>
                        {channel.name}
                    </Badge>
                    <Input
                        type="text"
                        name="message"
                        ref={ref}
                        autoComplete="off"
                        color="orange.200"
                        bgColor="orange.700"
                        _focus={{
                            borderColor: "orange.500",
                            boxShadow: "none",
                        }}
                        borderRadius="sm"
                        autoFocus={!isMobile}
                    />
                    <Button type="submit">Send</Button>
                </HStack>
            </form>
        </Box>
    )
})

export default Channel
