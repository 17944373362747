import { Project, ProjectCategory, Projects } from "@/api/projects"
import { useFaction, useGameId, useProjects } from "@/hooks/query";
import { useStore } from "@/store";
import { Box, IconButton, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { DataSet } from "vis-data/peer";
import { Edge, Network } from "vis-network/peer";
import "vis-network/styles/vis-network.css";
import { default as ProjectComponent } from "./Project";
import { Node, NodeOptions } from "vis-network/peer";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { useIsMobile } from "@/hooks/utils";
import { AnimatePresence } from "motion/react";
import { AnimatedBox } from "../common/Motion";
import { IoCloseOutline } from "react-icons/io5";
import i18n from "@/i18n";


const CATEGORY_COLORS = {
    [ProjectCategory.MILITARY]: "#ff0000",
    [ProjectCategory.WORKER]: "#0000ff",
    [ProjectCategory.ECONOMY]: "#2d6a4f",
    [ProjectCategory.VICTORY]: "#ffff00",
    [ProjectCategory.SUPPORT]: "#ff00ff",
}

const imageCache: { [key: string]: HTMLImageElement } = {};

const projectsToNode = (queryClient: QueryClient, gameId: number): DataSet<Node> => {
    const projects = (queryClient.getQueryData(["projects", gameId]) as Projects).permanent
    const nodes: Node[] = projects.map((project) => {
        return {
            id: project.id,
            label: project.name as string,
            shape: "custom",
            size: project.type == "building" ? 50 : 15,
            shadow: true,
            image: project.type == "building" ? `/images/buildings/${project.name.toLowerCase()}.png` : undefined,
            title: project.type == "building" ? i18n.t(`buildings.${project.name}.title`) : i18n.t(`permanentProjects.${project.name}.title`),
            ctxRenderer: function ({ ctx, x, y, state: { selected, hover }, style }: { ctx: CanvasRenderingContext2D, x: number, y: number, state: { selected: boolean, hover: boolean }, style: any }) {
                // const time = Date.now() * 0.001;
                // const glowIntensity = (Math.sin(time * 2) + 1) / 2;

                // let animationFrameId: number;

                // const animate = () => {
                //     if (ctx.canvas.parentElement) {
                //         ctx.canvas.parentElement.dispatchEvent(new Event('redraw'));
                //     }
                //     animationFrameId = requestAnimationFrame(animate);
                // };

                // // Démarrer l'animation
                // animate();
                const nodeSize = project.type == "building" ? 80 : project.type == "ui" ? 50 : 30;
                return {
                    drawNode() {
                        const projects = queryClient.getQueryData(["projects", gameId]) as Projects
                        const updatedProject = projects?.permanent.find(p => p.id === project.id)
                        if (!updatedProject) return
                        // console.log("drawNode", project.id, project.unlocked, updatedProject?.unlocked)
                        const radius = updatedProject.type == "building" ? 50 : updatedProject.type == "ui" ? 30 : 20;
                        const progress = updatedProject.completed ? 1 : updatedProject?.workers / updatedProject?.cost
                        // console.log("progress", progress)
                        // console.log("updatedProject", updatedProject)

                        // Configuration de l'ombre avec animation
                        const opacity = updatedProject.completed ? 1 : 0.5

                        const gradient = ctx.createRadialGradient(x, y, 0, x, y, radius);
                        gradient.addColorStop(0, `rgba(255, 255, 255, ${opacity})`); // Vert clair au centre
                        gradient.addColorStop(1, CATEGORY_COLORS[project.category]); // Couleur de la catégorie aux bords

                        if (updatedProject.type == "building") {
                            const cachedImage = imageCache[updatedProject.name];
                            
                            ctx.save();
                            ctx.beginPath();
                            ctx.arc(x, y, radius, 0, 2 * Math.PI);
                            ctx.clip();
                            
                            ctx.globalAlpha = updatedProject.unlocked ? 1 : 0.2;
                            
                            if (cachedImage && cachedImage.complete) {
                                ctx.drawImage(
                                    cachedImage,
                                    x - radius,
                                    y - radius,
                                    radius * 2,
                                    radius * 2
                                );
                            } else {
                                // Fallback en attendant le chargement
                                ctx.fillStyle = CATEGORY_COLORS[project.category];
                                ctx.fill();
                            }

                            // Dessiner le contour du cercle
                            ctx.strokeStyle = CATEGORY_COLORS[project.category];
                            ctx.lineWidth = 2;
                            ctx.stroke();
                            
                            ctx.restore();
                        }
                        else {
                            // Dessiner le cercle de fond
                            ctx.beginPath();
                            ctx.arc(x, y, radius, 0, 2 * Math.PI);
                            ctx.globalAlpha = updatedProject.unlocked ? 1 : 0.3;
                            ctx.fillStyle = gradient;
                            ctx.fill();
                        }

                        // Réinitialiser l'ombre pour les éléments suivants
                        ctx.shadowColor = 'transparent';
                        ctx.shadowBlur = 0;
                        ctx.shadowOffsetY = 0;

                        // Effet de brillance supplémentaire
                        // ctx.beginPath();
                        // ctx.arc(x, y, radius * 1.2, 0, 2 * Math.PI);
                        // ctx.fillStyle = `rgba(168, 230, 207, ${glowIntensity * 0.2})`; // Halo extérieur
                        // ctx.fill();

                        // Dessiner le remplissage selon la progression
                        if (progress > 0) {
                            const opacity = updatedProject.completed ? 1 : 0.5
                            ctx.beginPath();
                            ctx.moveTo(x, y);
                            ctx.arc(x, y, radius, -Math.PI / 2, (-Math.PI / 2) + (2 * Math.PI * progress));
                            ctx.lineTo(x, y);
                            ctx.fillStyle = `rgba(0, 50, 30, ${opacity})`;
                            ctx.fill();
                        }

                        // Redessiner le contour
                        ctx.beginPath();
                        ctx.arc(x, y, radius, 0, 2 * Math.PI);
                        ctx.strokeStyle = 'orange';
                        ctx.stroke();
                    },
                    nodeDimensions: { width: nodeSize, height: nodeSize },
                }
            }

            // data: { project: project.id, tier: project.tier },
            // position: { x: x, y: y },
            // draggable: false,
            // selectable: true,
        }
    })
    nodes.push({
        id: 0,
        shape: "dot",
        color: "rgba(0, 50, 30, 1)",
        shadow: true,
        size: 50,
        image: undefined,
        // data: { project: project.id, tier: project.tier },
        // position: { x: 0, y: 0 },
        // draggable: false,
        // selectable: true,
    })
    return new DataSet(nodes)
}

const projectsToEdges = (projects: Project[] | undefined) => {
    if (!projects) return new DataSet<Edge>()

    const edges: Edge[] = []

    // Pour chaque projet
    projects.forEach((project) => {
        // Si c'est un projet de tier 1, connecter directement au root (0)
        if (project.tier === 1) {
            edges.push({
                from: 0,
                to: project.id,
                color: "orange"
            })
        }

        // Pour chaque projet, créer des liens vers les projets qui le requièrent
        projects.forEach((dependent) => {
            if (dependent.requires?.includes(project.id)) {
                edges.push({
                    from: project.id,
                    to: dependent.id,
                    color: "orange",
                    dashes: dependent.completed ? false : [10, 20, 10, 20],
                })
            }
        })
    })
    return new DataSet(edges)

}



interface DrawerProjectProps {
    children: React.ReactNode
}

const DrawerProject: FunctionComponent<DrawerProjectProps> = observer(({ children }) => {
    const { projectsStore } = useStore()

    return (
        <AnimatePresence >
            {projectsStore.selectedNode && (
                <AnimatedBox
                    initial={{ opacity: 0, bottom: -140 }}
                    animate={{ opacity: 1, bottom: 33 }}
                    exit={{ opacity: 0, bottom: -140 }}
                    position="fixed"
                    left="0"
                    right="0"
                    zIndex={1000}
                >
                    <IconButton
                        aria-label="close"
                        size="sm"
                        position="absolute"
                        right="-1"
                        top="-1"
                        onClick={() => projectsStore.setSelectedNode(null)}
                        zIndex={1}
                    >
                        <IoCloseOutline />
                    </IconButton>
                    <Box pointerEvents={"auto"}>{children}</Box>

                </AnimatedBox>

            )}
        </AnimatePresence>
    )
})

const DevelopmentTree: FunctionComponent = observer(() => {
    const { projectsStore } = useStore()
    const { data: projects, isLoading, isError } = useProjects()
    const container = useRef<HTMLDivElement>(null)
    const permanent = projects?.permanent
    // const network = useRef<Network | null>(null)
    const position = useRef<{ x: number, y: number } | null>(null)
    const popup = useRef<HTMLDivElement>(null)
    const queryClient = useQueryClient()
    const gameId = useGameId()

    // const initialNodes = useMemo(() => projectsToNode(permanent), [permanent])
    // const initialEdges = useMemo(() => projectsToEdges(permanent), [permanent])

    // if (!initialNodes.length || !initialEdges.length) return null

    useEffect(() => {
        if (!projects?.permanent.length) return
        if (!container.current) return
        const nodes = projectsToNode(queryClient, gameId)
        const edges = projectsToEdges(permanent)
        const data = {
            nodes: nodes,
            edges: edges
        };
        const options = {
            nodes: {
                shape: "box",
                size: 200,
            },
            edges: {
                width: 2,
                shadow: true,
            },
            layout: {
                randomSeed: 25
            },
            interaction: {
                tooltipDelay: 0,
            },
            // "physics": false,
            physics: {
                // stabilization: {
                //     enabled: true,
                //     iterations: 50000,
                //     updateInterval: 25,
                //   },
                // "repulsion": {
                //     "centralGravity": 0,
                //     "springLength": 40,
                //     "springConstant": 0.48,
                //     "nodeDistance": 200,
                //     "damping": 1
                // },
                barnesHut: {
                    // theta: 0.9,
                    // avoidOverlap: 0.5,
                    damping: 1,
                    // gravity: 50000

                },
                // forceAtlas2Based: {
                //     // gravitationalConstant: -200,
                //     // centralGravity: 0.01,
                //     // springLength: 100,
                //     // springConstant: 0.01,
                //     damping: 0.9,
                // },
                // hierarchicalRepulsion: {
                //     damping: 0.9,
                //     nodeDistance: 500,
                //     centralGravity: 0.01,
                //     springLength: 100,
                //     springConstant: 0.01,
                //     avoidOverlap: 0.5,
                // },
                solver: "barnesHut"
            },
            // configure: {
            //     enabled: true,
            //     showButton: true
            // }

        };
        window.network = new Network(container.current, data, options)
        window.network.on('click', function (properties) {
            const ids = properties.nodes;
            const clickedNodes = nodes.get(ids);

            if (clickedNodes.length == 0) projectsStore.setSelectedNode(null)
            else projectsStore.setSelectedNode(clickedNodes[0].id as number)

            // console.log(network.getPosition(clickedNodes[0].id as number))
        });

    }, [projects?.permanent.length])

    // Précharger les images au montage du composant
    useEffect(() => {
        if (!projects?.permanent) return;
        
        projects.permanent.forEach(project => {
            if (project.type === "building") {
                const img = new Image();
                img.src = `/images/buildings/${project.name.toLowerCase()}.png`;
                imageCache[project.name] = img;
            }
        });
    }, [projects?.permanent]);

    const updatePopupPosition = useCallback(() => {
        if (!projectsStore.selectedNode) return
        const pos = window.network?.canvasToDOM(
            window.network?.getPosition(projectsStore.selectedNode as number)
        )
        if(pos) position.current = pos
        // position.current = window.network?.canvasToDOM(
        //     window.network?.getPosition(projectsStore.selectedNode as number)
        // )
        // console.log(position.current)
        if (popup.current) {
            popup.current.style.top = `${position.current?.y}px`
            popup.current.style.left = `${position.current?.x}px`
        }
    }, [projectsStore.selectedNode])

    useEffect(() => {
        updatePopupPosition()
    }, [projectsStore.selectedNode])

    window.network?.on('dragging', function (properties) {
        updatePopupPosition()
    })
    window.network?.on('zoom', function (properties) {
        updatePopupPosition()
    })

    const isMobile = useIsMobile()

    return (
        <Box position="relative">
            <div id="development-tree" ref={container} style={{ height: 500 }}></div>
            {isMobile && <DrawerProject>
                {projectsStore.selectedNode && <ProjectComponent id={projectsStore.selectedNode} />}
            </DrawerProject>}


            {!isMobile && (
                <AnimatePresence>
                    {projectsStore.selectedNode && (
                        <AnimatedBox
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            position="absolute"
                            ref={popup}
                            // top={`${position.current?.y}px`}
                            // left={`${position.current?.x}px`}
                            zIndex={1000}
                        // Propriétés spécifiques à Box
                        >
                            <ProjectComponent id={projectsStore.selectedNode} />
                        </AnimatedBox>
                    )}
                </AnimatePresence>
            )}
        </Box>
    )
})

export default DevelopmentTree
