import { Factions } from '../../types';
import { GameScene } from './scene/world';
import GesturesPlugin from 'phaser3-rex-plugins/plugins/gestures-plugin.js';


export const gameConfig: Phaser.Types.Core.GameConfig = {
    title: 'MMO',
    type: Phaser.AUTO,
    width: 600,
    height: 600,
    parent: "phaser",
    scene: [GameScene],
    // autoFocus: false,
    // domCreateContainer : true,


    input: {
        keyboard: true,
        mouse: true,
        touch: true,
        gamepad: false
    },
    fps: {
        target: 60,
        forceSetTimeOut: true
    },
    plugins: {
        scene: [
            {
                key: 'rexGestures',
                plugin: GesturesPlugin,
                mapping: 'rexGestures'
            },
        ]
    },

    backgroundColor: 'black',
    render: { pixelArt: true, antialias: false },
    dom: {
        // createContainer: true,
        pointerEvents: "auto",
    }

};

export const TILE_SIZE = 32
export const MOBILE_BREAKPOINT = 992

// export const FACTIONS_COLOR = {
//     [Factions.BLUE]: 0x0000ff,
//     [Factions.RED]: 0xff0000,
//     [Factions.GREEN]: 0x00ff00,
//     [Factions.YELLOW]: 0xffff00,
//     [Factions.NEUTRAL]: 0xffffff,
// }
