import { Box, Button, Text } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent, useState, useRef } from "react"
import Panel from "../components/common/Panel"
import { usePrevious } from "../hooks/utils"
import { Tooltip } from "@/components/ui/tooltip"



const DebugPage: FunctionComponent = () => {

    const [level, setLevel] = useState(0)
    const previous = usePrevious(level);


    return (
        <Box>
            <Button onClick={() => setLevel(level + 1)}>Add</Button>

            <Panel visual="withBorder" animKey={level} animated={true}>
                <Text>Test test test {level}</Text>
                <Text>Previous: {previous}</Text>
            </Panel>
            
            {Array.from({ length: 3000 }).map((_, i) => (
                <Tooltip content={`Tooltip ${i}`} key={i}>
                    <Text>Test {i}</Text>
                </Tooltip>
            ))}
        </Box>
    )
}

export default DebugPage
