
import { FunctionComponent } from "react"
import { BsDiscord, BsGoogle, BsSteam } from "react-icons/bs"
import { Button } from "../ui/button"

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL

interface LoginButtonsProps {
    hide?: string[] /** hide some buttons */
}

const LoginButtons: FunctionComponent<LoginButtonsProps> = ({ hide = [] }) => {
    return (
        <>
            {!hide.includes('steam') &&
                <Button colorPalette="gray" w={"300px"} onClick={() => window.location.href = `${API_BASE_URL}login/steam`} title="Steam">
                    <BsSteam />Sign in with Steam
                </Button>}
            {!hide.includes('discord') &&
                <Button colorPalette="blue" w={"300px"} onClick={() => window.location.href = `${API_BASE_URL}login/discord`} title="Discord">
                    <BsDiscord />Sign in with Discord
                </Button>}
            {!hide.includes('google-oauth2') &&
                <Button colorPalette="light" w={"300px"} onClick={() => window.location.href = `${API_BASE_URL}login/google-oauth2`} title="Google">
                    <BsGoogle />Sign in with Google
                </Button>}
        </>

    )
}

export default LoginButtons

