import { Box, Heading, SimpleGrid, Stack, Text } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent } from "react"
import Players from "../components/faction/Players"
import Surveys from "../components/survey/Surveys"
import Activity from "../components/activities/Activity"
import { useStore } from "../store"
import Panel from "../components/common/Panel"
import Activities from "../components/activities/Activities"
import Events from "../components/events/Events"

const FactionActivities = observer(() => {
    const { socketStore } = useStore()
    return (
        <Panel height={["100%", "100%", "calc(100% - 2rem)"]} overflow="hidden">
            <Heading as="h3" size="md" mb="2">
                Logs
            </Heading>
            <Activities
                list={socketStore.factionActivities}
                maxHeight="100%"
                height={["calc(100% - 1rem)", "calc(100% - 1rem)", "calc(100% - 1rem)"]}
                initialized={socketStore.factionActivitiesInitialized}
            />
        </Panel>
    )
})

const FactionPage: FunctionComponent = observer(() => {
    return (
        <Box>
            <SimpleGrid columns={[1, 1, 2]} height={["800px", "800px", "400px"]} gap={[8, 8, 0]} overflowY="hidden">
                <FactionActivities />
                <Panel height={["100%", "100%", "calc(100% - 2rem)"]} overflow="hidden">
                    <Events />
                
                </Panel>
            </SimpleGrid>
            <Surveys />
            <Players />
        </Box>
    )
})

export default FactionPage
