import { Box, Text } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent } from "react"
import { LeaderRole } from "../../api/faction"
import { useFactions, useIsUnlocked } from "../../hooks/query"
import { Message, MessageType } from "../../store/ChatStore"
import { UnlockType } from "../../types"
import { wrapCoordinates, wrapLinks } from "../../utils/chat"
import DateComponent from "../common/DateComponent"
import GameIcon, { GameIconType } from "../common/GameIcon"
import Link from "../common/Link"
import { useStore } from "@/store"

interface MessageProps extends Message {
    channelId: string
    isLast: boolean
}

const MessageComponent: FunctionComponent<MessageProps> = observer(
    ({ id, content, player, created_at, channelId, isLast, message_type }) => {
        const { settingsStore } = useStore()
        const factionColor = settingsStore.getFactionColors(player.faction).username
        const mapIsUnlocked = useIsUnlocked(UnlockType.UI_WORLD)
        const faction = useFactions().data?.find((f) => f.id === player.faction)

        const wrappedContent = mapIsUnlocked ? wrapLinks(wrapCoordinates(content)) : content

        // const channel = chatStore.getChannel(channelId)
        // // check isLast because we don't show line for the last message
        // const isLastRead = !isLast && channel?.lastReadMessage === id
        // console.log("refresh")
        return (
            <Box>
                {player.leader_role && (
                    <Text as="span" fontSize="xs" color="purple.700">
                        {player.leader_role == LeaderRole.Leader && faction?.leader_name}
                        {player.leader_role == LeaderRole.General && "General"}
                        {player.leader_role == LeaderRole.Diplomat && "Diplomat"}{" "}
                    </Text>
                )}
                {player.premium ? (
                    // <Link
                    //     to={`/player/${player.username}`}
                    //     color={factionColor}
                    //     bgColor="black"
                    //     opacity="0.5"
                    //     p="2px"
                    //     borderRadius={"md"}
                    // >
                    //     {player.username}
                    // </Link>
                    <Link
                        to={`/player/${player.username}-${player.id}`}
                        backgroundColor={player.faction ? factionColor : "gray.300"}
                        color={player.faction ? "goldenrod" : "black"}
                        border="1px solid"
                        borderColor="goldenrod"
                        textDecor={"none"}
                        p="1px"
                    >
                        {player.username}
                    </Link>
                ) : (
                    <Link to={`/player/${player.username}-${player.id}`} color={factionColor}>
                        {player.username}
                    </Link>
                )}{" "}
                {message_type === MessageType.MESSAGE && (
                    <>
                        :{" "}
                        <Text
                            className="message"
                            as="span"
                            fontSize="sm"
                            dangerouslySetInnerHTML={{ __html: wrappedContent }}
                        ></Text>
                    </>
                )}
                {message_type === MessageType.ATTACK && (
                    <Text as="span" fontSize="sm" color="blue.800" className="message">
                        commands to <GameIcon name={GameIconType.ATTACK} /> attack{" "}
                        <Text
                            as="span"
                            dangerouslySetInnerHTML={{ __html: wrappedContent }}
                        ></Text>
                        .
                    </Text>
                )}
                {message_type === MessageType.DEFEND && (
                    <Text as="span" fontSize="sm" color="blue.800" className="message">
                        commands to <GameIcon name={GameIconType.DEFEND}/> defend {" "}
                        <Text
                            as="span"
                            dangerouslySetInnerHTML={{ __html: wrappedContent }}
                        ></Text>
                        .
                    </Text>
                )}
                <Text fontSize="xs" as="span" pr="1" color="gray.300" display="inline-block" float="right">
                    <DateComponent date={created_at * 1000} showTime />
                </Text>
                
            </Box>
        )
    }
)

export default MessageComponent
