import { Spinner } from "@chakra-ui/react"
import { FunctionComponent } from "react"

interface LoadingProps {
    size?: "xs" | "sm" | "md" | "lg" | "xl"
}
const Loading: FunctionComponent<LoadingProps> = ({ size = "xl" }) => {
    return <Spinner size={size} />
}

export default Loading
