import { Box, Button, Container, Flex, HStack, Heading, SimpleGrid, Text } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent, useEffect } from "react"
import { Outlet, useLocation, useMatch, useMatches, useNavigate, useParams } from "react-router-dom"
import Footer from "./Footer"
import Header from "./Header"
import { useStore } from "../../store"
import { NotificationsComponent } from "./Notifications"
import SimpleHeader from "./SimpleHeader"
import MainMenu from "./MainMenu"
import { useQueryClient } from "@tanstack/react-query"
import { useImportantNews } from "../../api/news"
import { DialogRoot, DialogContent, DialogBody, DialogFooter, DialogActionTrigger, DialogCloseTrigger, DialogHeader, DialogTitle, DialogBackdrop } from "@/components/ui/dialog"
import { useIsMobile } from "@/hooks/utils"

const Root: FunctionComponent = observer(() => {
    // redirect to /play if the url matches root
    const queryClient = useQueryClient()
    const { userStore, socketStore } = useStore()
    const location = useLocation()
    const navigate = useNavigate()
    const matches = useMatches()
    const inGame = matches.some((match) => match.id === "play")
    const params = useParams()
    const { data: news } = useImportantNews()
    const isMobile = useIsMobile()

    useEffect(() => {
        if (userStore.apiToken && location?.pathname === "/") {
            navigate("/games")
        }
    }, [location?.pathname])

    useEffect(() => {
        if (params.gameId) {
            userStore.setGameId(parseInt(params.gameId, 10))
        }
    }, [params.gameId])

    useEffect(() => {
        if (inGame && userStore.player?.faction) {
            socketStore.startFactionSocket()
        }
        return () => {
            socketStore.stopFactionSocket()
        }
    }, [params.gameId, userStore.player?.faction, inGame])

    return (
        <Box>
            {/* {userStore.gameId && ( */}
            <>
                <Box
                    bgImage={[null, null, "url('/images/wallpaper.webp')"]}
                    backgroundSize="cover"
                    pos={"fixed"}
                    left={0}
                    right={0}
                    top={0}
                    bottom={0}
                >
                    <Box
                        bgImage="linear-gradient(to bottom, transparent, {colors.orange.500})"
                        pos={"fixed"}
                        left={0}
                        right={0}
                        top={0}
                        bottom={0}
                    />
                </Box>
                <Container
                    maxWidth="120rem"
                    bg="orange.600"
                    centerContent
                    my={[0, 0, 2]}
                    boxShadow="xl"
                    border={"2px solid"}
                    borderColor="orange.800"
                    p="0"
                    marginBottom="50px"

                >
                    <Flex direction="column" w="full" h="full">
                        {inGame && <Header />}
                        {!inGame && <SimpleHeader />}
                        {/* <Link to={`/`}>
                            Root
                        </Link>
                        <Link to={`/play/17`}>
                            Game
                        </Link> */}

                        <Flex alignItems={"start"} direction={["column", "column", "row"]} flex="1">
                            {!inGame && (
                                <Box minWidth="300px" maxWidth={"500px"}
                                    alignSelf="stretch">
                                    <MainMenu />
                                </Box>
                            )}
                            <Box w="full">
                                {news && !inGame && (
                                    <Box layerStyle="warning" m="4">
                                        <Text fontSize="lg">{news.title}</Text>
                                        <Text dangerouslySetInnerHTML={{ __html: news.content }} />
                                    </Box>
                                )}
                                <Outlet />
                            </Box>
                        </Flex>
                        {!inGame || !isMobile && <Footer />}
                        <NotificationsComponent />
                    </Flex>
                </Container>


                <DialogRoot lazyMount open={!!socketStore.newGamePopup} onOpenChange={() => socketStore.setNewGamePopup(null)}>
                    <DialogBackdrop />
                    <DialogContent>
                        <DialogCloseTrigger />
                        <DialogHeader>
                            <DialogTitle>New game started</DialogTitle>
                        </DialogHeader>
                        <DialogFooter justifyContent="center">
                            <Button onClick={() => {
                                // queryClient.invalidateQueries({ queryKey: ["games"] })
                                // queryClient.invalidateQueries({ queryKey: ["hqInfo", socketStore.newGamePopup] })
                                // queryClient.invalidateQueries({ queryKey: ["config", socketStore.newGamePopup] })
                                // queryClient.invalidateQueries({ queryKey: ["game", socketStore.newGamePopup] })
                                // queryClient.invalidateQueries({ queryKey: ["player", socketStore.newGamePopup] })
                                // navigate(`/play/${socketStore.newGamePopup}`)
                                // socketStore.setNewGamePopup(null)
                                window.location.href = `/play/${socketStore.newGamePopup}`

                            }}>
                                Join the game
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </DialogRoot >

            </>
        </Box>
    )
})

export default Root
