import { defineSlotRecipe } from "@chakra-ui/react";

export const tooltipSlotRecipe = defineSlotRecipe({
  slots: ['content', 'arrow', 'arrowTip'],
  className: "chakra-tooltip",
  base: {
    content: {
      fontSize: "sm",
      bg: "orange.800",
      color: "orange.200",
    },
    arrow: {
      "--arrow-background": "orange.800",
    },
    arrowTip: {
      borderColor: "orange.800",
    },
  },
})