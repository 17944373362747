import { FunctionComponent } from "react"
import { BuildingType, UserBuilding } from "../../types"
import { Box, Center, Flex, HStack, Heading, Image, SimpleGrid, Spinner, Stack, Text } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Mod, destroyBuilding, upgradeBuilding } from "../../api/game"
import { UISpendResources, useHasEnoughResources, useIsMobile } from "../../hooks/utils"
import { useConfig, useGameId } from "../../hooks/query"
import Panel from "../common/Panel"
import Resources from "../resources/Resources"
import { ImArrowUp, ImCross } from "react-icons/im"
import BuildModal from "./BuildModal"
import ModList from "../resources/ModList"
import { useTranslation } from "react-i18next"
import Number from "../common/Number"
import { Button } from "../ui/button"

interface BuildingComponentProps extends UserBuilding {
    condensed?: boolean
}
export const BuildingComponent: FunctionComponent<BuildingComponentProps> = ({
    id,
    name,
    level,
    upgradeCost,
    condensed = false,
}) => {
    const queryClient = useQueryClient()
    const gameId = useGameId()
    const upgradeMutation = useMutation({
        mutationFn: () => upgradeBuilding(gameId, id),
        onMutate: () => {
            UISpendResources({ gameId, resources: upgradeCost })
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["hqInfo", gameId] })
            queryClient.invalidateQueries({ queryKey: ["effects", gameId] })
            queryClient.invalidateQueries({ queryKey: ["gatherResources", gameId] })
        },
    })

    const destroyMutation = useMutation({
        mutationFn: () => destroyBuilding(gameId, id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["hqInfo", gameId] })
            queryClient.invalidateQueries({ queryKey: ["effects", gameId] })
            queryClient.invalidateQueries({ queryKey: ["gatherResources", gameId] })
        },
    })

    const config = useConfig()

    const buildingInfo = config?.buildings.find((building) => building.name == name)

    const enoughResources = useHasEnoughResources(upgradeCost)

    const { t } = useTranslation()

    if (!buildingInfo) return <></>

    const buttons = (
        <HStack wrap="wrap" gap={1} align="center" justify={"center"} p="2">
            <Box
                border={"2px solid"}
                borderColor="orange.600"
                borderStyle={"outset"}
                borderRadius="sm"
                boxShadow={"md"}
            >
                <HStack wrap="wrap" align={"center"} justify={"center"} gap={1}>
                    <Box>
                        <Resources {...upgradeCost}></Resources>
                    </Box>
                    <Box>
                        <Button
                            // isLoading={upgradeMutation.isPending}
                            onClick={() => {
                                upgradeMutation.mutate()
                            }}
                            disabled={!enoughResources || upgradeMutation.isPending}
                            size="sm"
                            aria-label="Upgrade building"
                        >
                            {upgradeMutation.isPending ? <Spinner size="xs" mr="0.5" /> : <ImArrowUp />}
                            <Text display={["none", "none", "block"]}>Upgrade</Text>
                        </Button>
                    </Box>
                </HStack>
            </Box>
            {!buildingInfo.unique && (
                <Box>
                    <Button
                        confirm
                        onClick={() => {
                            destroyMutation.mutate()
                        }}
                        colorPalette="red"
                        size="sm"
                        aria-label="Destroy building"
                    >
                        <ImCross />
                        <Text display={["none", "none", "block"]}>Destroy</Text>
                    </Button>
                </Box>
            )}
        </HStack>
    )

    if (condensed)
        return (
            <HStack
                justifyContent={"space-between"}
                bgColor="orange.500"
                borderBottom="1px solid"
                borderColor="orange.800"
                borderRadius={"sm"}
                boxShadow={"md"}
                flexDirection={["column", "column", "row"]}
                gap="0"
            >
                <HStack alignSelf={"start"}>
                    <Image
                        src={`/images/buildings/${name.toLowerCase()}.png`}
                        w={"50px"}
                        h={"50px"}
                        objectFit={"cover"}
                        aria-hidden
                        p="1"
                    />
                    <Stack gap="0">
                        <Heading as="h4" size="md" fontSize="md">
                            {t(`buildings.${name}.title`)}
                            <Text textStyle={"discreet"} as="span" ms="1" fontWeight={"normal"}>
                                {" "}
                                - level <Number value={level} animated />
                            </Text>
                        </Heading>
                        <Box fontSize="small">
                            <ModList value={buildingInfo.baseEffects} level={level} />
                        </Box>
                    </Stack>
                </HStack>
                <Box>{buttons}</Box>
            </HStack>
        )

    return (
        <Panel visual="withBorder" animKey={level} animated={true}>
            <Flex justifyContent={"space-between"} wrap={["wrap", "nowrap"]}>
                <Flex justifyContent={"center"} alignItems={"center"} width={["full", "fit-content"]} mb={[1, 0]}>
                    <Image
                        src={`/images/buildings/${name.toLowerCase()}.png`}
                        w={"120px"}
                        h={"120px"}
                        objectFit={"cover"}
                        aria-hidden
                    />
                </Flex>
                <Stack flexGrow={1} justifyContent={"space-between"} ps="2">
                    <HStack alignItems={"stretch"}>
                        <Flex justifyContent={"space-between"} flexDir={"column"} flexGrow={1} me="2">
                            <HStack
                                justifyContent={"space-between"}
                                borderBottom={"2px solid"}
                                borderColor="orange.600"
                                wrap={"wrap"}
                            >
                                <Heading as="h4" size="md">
                                    {t(`buildings.${name}.title`)}
                                </Heading>

                                <Text textStyle={"discreet"}>
                                    level <Number value={level} animated />
                                </Text>
                            </HStack>
                            <Box>
                                <ModList value={buildingInfo.baseEffects} level={level} />
                            </Box>
                        </Flex>
                    </HStack>
                    <Flex justifyContent={"end"}>{buttons}</Flex>
                </Stack>
            </Flex>
        </Panel>
    )
}

interface BuildingPlaceholderProps {
    withBuildButton: boolean
}
export const BuildingPlaceholder: FunctionComponent<BuildingPlaceholderProps> = ({ withBuildButton }) => {
    return (
        <Panel
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            background="repeating-linear-gradient(
            45deg,
            #BF9B6E50,
            #BF9B6E50 10px,
            #6A502F50 10px,
            #6A502F50 20px
          );"
            minH="100px"
        >
            <Stack alignItems={"center"} justifyContent={"center"} h="full">
                <Center>Building slot</Center>
                {withBuildButton && (
                    <Center>
                        <BuildModal />
                    </Center>
                )}
            </Stack>
        </Panel>
    )
}
