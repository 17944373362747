import { defineSlotRecipe } from "@chakra-ui/react"


// define the base component styles
export const menuRecipe = defineSlotRecipe({
  slots: ['content', 'item', 'itemText', 'itemGroupLabel', 'indicator', 'itemCommand', 'separator'],

  variants: {


    variant: {
      // define the part you're going to style
      solid: {
        trigger: {},
        content: {
          // this will style the MenuList component
          py: '1',
          bg: 'orange.700',
          borderRadius: 'sm',
          border: 'none',
          boxShadow: 'md'
        },
        item: {
          // this will style the MenuItem and MenuItemOption components
          cursor: 'pointer',
          color: 'orange.200',
          bg: 'orange.700',
          _hover: {
            bg: 'teal.600',
          },
          _focus: {
            bg: 'teal.600',
          },
          _highlighted: {
            bg: 'transparent',
          },
          // height: "20px",
        },
        // groupTitle: {
        //   // this will style the text defined by the title prop
        //   // in the MenuGroup and MenuOptionGroup components
        //   textTransform: 'uppercase',
        //   color: 'white',
        //   textAlign: 'center',
        //   letterSpacing: 'wider',
        //   opacity: '0.7',
        // },
        // command: {
        //   // this will style the text defined by the command
        //   // prop in the MenuItem and MenuItemOption components
        //   opacity: '0.8',
        //   fontFamily: 'mono',
        //   fontSize: 'sm',
        //   letterSpacing: 'tighter',
        //   pl: '4',
        // },
        // divider: {
        //   // this will style the MenuDivider component
        //   my: '1',
        //   bgColor: 'orange.700',
        //   borderColor: 'orange.400',
        //   borderBottom: '2px solid',
        // },
      }
    }
  }
})
