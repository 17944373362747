// import { Box, Heading, Text } from "@chakra-ui/react"
// import { observer } from "mobx-react-lite"
// import { FunctionComponent } from "react"
// import Activities from "../activities/Activities"
// import { useStore } from "../../store"

// const BattleLog: FunctionComponent = observer(() => {
//     const { socketStore } = useStore()
//     return (
//         <Box minHeight="200px" maxHeight="100%" w="full" bgColor={"orange.500"}>
//             <Heading
//                 size="sm"
//                 mb={2}
//                 borderBottom="1px solid"
//                 borderColor="orange.200"
//                 position="sticky"
//                 top="0"
//                 zIndex="1"
//                 bgColor={"orange.600"}
//             >
//                 <Text p="2">Battle log</Text>
//             </Heading>
//             <Activities
//                 list={socketStore.battleActivities}
//                 maxHeight="calc(100% - 42px)"
//                 bgColor={"orange.500"}
//                 initialized={socketStore.battleActivitiesInitialized}
//             />
//         </Box>
//     )
// })

// export default BattleLog

import {
    Box,
    Button,
    HStack,
    Tabs
} from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent } from "react"
import Activities from "../activities/Activities"
import { useStore } from "../../store"
import { useQuery } from "@tanstack/react-query"
import { useGameId } from "../../hooks/query"
import { getCaseActivities } from "../../api/world"
import { MdFilterAlt } from "react-icons/md"
import { PlayerActivity, PlayerActivityType } from "../../api/player"
import { MenuRoot, MenuTrigger, MenuContent, MenuRadioItemGroup, MenuRadioItem, MenuItemGroup } from "@/components/ui/menu"

const typeToActivities = {
    military: [
        PlayerActivityType.LOOT,
        PlayerActivityType.SOLDIERS_ATTACK,
        PlayerActivityType.SOLDIERS_DEFEND,
        PlayerActivityType.SUPPORT_SENT,
    ],
    workers: [
        PlayerActivityType.DISMANTLE_WORKERS_SENT,
        PlayerActivityType.IMPROVEMENT_WORKERS_SENT,
        PlayerActivityType.FORTIFICATION_WORKERS_SENT,
    ],
}

const filterActivities = (activities: PlayerActivity[], type: "all" | "military" | "workers", quantity: number) => {
    const allowedActivities = type !== "all" ? typeToActivities[type as keyof typeof typeToActivities] : null
    const minimumAmount = quantity
    const filteredActivities = activities?.filter(
        (a) =>
            (allowedActivities === null || (allowedActivities && allowedActivities.includes(a.type))) &&
            (a.amount === null || a.amount! >= minimumAmount || a.data.captured == true)
    )
    return filteredActivities
}

const CaseActivities: FunctionComponent = observer(() => {
    const { mapStore, settingsStore } = useStore()
    const gameId = useGameId()
    const { data: activities, isLoading } = useQuery({
        queryKey: ["caseActivities", mapStore.selectedTile?.x, mapStore.selectedTile?.y],
        queryFn: () => getCaseActivities(gameId, mapStore.selectedTile!.x, mapStore.selectedTile!.y),
        enabled: mapStore.selectedTile?.x !== undefined && mapStore.selectedTile?.y !== undefined,
    })
    const filteredActivities = filterActivities(
        activities ?? [],
        settingsStore.battleLogType,
        settingsStore.battleLogQuantity
    )
    return <Activities list={filteredActivities ?? []} maxHeight="calc(100% - 42px)" initialized={!isLoading} />
})

const BattleLog: FunctionComponent = observer(() => {
    const { socketStore, mapStore, settingsStore } = useStore()

    const filteredActivities = filterActivities(
        socketStore.battleActivities ?? [],
        settingsStore.battleLogType,
        settingsStore.battleLogQuantity
    )
    return (
        <Box id="battle-log" minHeight="100px" maxHeight="100%" w="full" bgColor={"orange.500"} p="1">
            <Tabs.Root lazyMount height="100%" overflowY="hidden" defaultValue="all">
                <HStack justify="space-between">
                    <Tabs.List>
                        <Tabs.Trigger value="all">All logs</Tabs.Trigger>
                        {mapStore.selectedTile && <Tabs.Trigger value="tile">Ter. logs</Tabs.Trigger>}
                    </Tabs.List>
                    <Box>
                        <MenuRoot size="sm" variant="solid">
                            <MenuTrigger as={Button} size="2xs" variant="outline" colorPalette="orange">
                                <MdFilterAlt />
                            </MenuTrigger>
                            <MenuContent>
                                <MenuItemGroup title="Quantity">
                                    <MenuRadioItemGroup
                                        value={"" + settingsStore.battleLogQuantity}
                                        onValueChange={(e: any) =>
                                            settingsStore.setBattleLogQuantity(parseInt(e.value as string, 10))
                                        }
                                    >
                                        <MenuRadioItem value={"1"}>All</MenuRadioItem >
                                        <MenuRadioItem value={"5"}>{">"} 5</MenuRadioItem >
                                        <MenuRadioItem value={"50"}>{">"} 50</MenuRadioItem >
                                    </MenuRadioItemGroup>
                                </MenuItemGroup>
                                <MenuItemGroup title="Type">
                                    <MenuRadioItemGroup
                                        value={settingsStore.battleLogType}
                                        onValueChange={(e: any) =>
                                            settingsStore.setBattleLogType(e.value as "all" | "military" | "workers")
                                        }
                                    >
                                        <MenuRadioItem value="all">All</MenuRadioItem >
                                        <MenuRadioItem value="military">Military</MenuRadioItem >
                                        <MenuRadioItem value="workers">Workers</MenuRadioItem >
                                    </MenuRadioItemGroup>
                                </MenuItemGroup>
                            </MenuContent>
                        </MenuRoot>
                    </Box>
                </HStack>

                <Box height="100%" p="0">
                    <Tabs.Content height="100%" p="0" value="all">
                        <Activities
                            list={filteredActivities}
                            maxHeight="calc(100% - 42px)"
                            bgColor={"orange.500"}
                            initialized={socketStore.battleActivitiesInitialized}
                        />
                    </Tabs.Content>
                    {mapStore.selectedTile && (
                        <Tabs.Content height="100%" p="0" value="tile">
                            <CaseActivities />
                        </Tabs.Content>
                    )}
                </Box>
            </Tabs.Root>

            {/* <Heading
                size="sm"
                mb={2}
                // borderBottom="1px solid"
                // borderColor="orange.200"
                position="sticky"
                top="0"
                zIndex="1"
                bgColor={"orange.600"}
            >
                <Text p="2">Battle log</Text>
            </Heading> */}
        </Box>
    )
})

export default BattleLog
