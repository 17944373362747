import {
    Box,
    CheckboxCheckedChangeDetails,
    ColorSwatch,
    Fieldset,
    Group,
    HStack,
    Heading,
    Input,
    Stack,
    Text,
    parseColor,
} from "@chakra-ui/react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { observer } from "mobx-react-lite"
import { FormEvent, FunctionComponent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { updatePlayer } from "../api/player"
import { useStore } from "../store"
import { GameStatus, getGames } from "../api/games"
import { Field } from "@/components/ui/field"
import { Checkbox } from "@/components/ui/checkbox"
import { Button } from "@/components/ui/button"
import {
    ColorPickerArea,
    ColorPickerContent,
    ColorPickerControl,
    ColorPickerEyeDropper,
    ColorPickerInput,
    ColorPickerLabel,
    ColorPickerRoot,
    ColorPickerSliders,
    ColorPickerTrigger,
} from "@/components/ui/color-picker"
import { Factions } from "@/types"
import { shadeColor } from "@/utils"


const Settings: FunctionComponent = observer(() => {
    const queryClient = useQueryClient()
    const { userStore, socketStore, settingsStore } = useStore()
    const [_formState, setFormState] = useState("pending")
    const [formError, setFormError] = useState("")
    const [username, setUsername] = useState("")

    const handleUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value
        // cleanup username
        if (!userStore.player!.isPremium)
            value = value.replace(/[\W]+/g, "").replace(" ", "")
        setUsername(value)
    }
    const [accessibilityMode, setAccessibilityMode] = useState(false)
    const handleAccessibility = (details: CheckboxCheckedChangeDetails) =>
        setAccessibilityMode(!!details.checked)

    const navigate = useNavigate()
    const usernameLastChange = userStore.player?.usernameLastChange
    const recentUserNameChange = usernameLastChange && new Date(usernameLastChange).getTime() > Date.now() - 1000 * 60 * 60 * 24 * 7

    useEffect(() => {
        if (userStore.player?.username) {
            setUsername(userStore.player.username)
            setAccessibilityMode(settingsStore.accessibilityMode)
        }
    }, [userStore.player?.username])

    const changeUser = useMutation({
        mutationFn: () => updatePlayer({ username }),
        onMutate: () => { },
        onSuccess: (response) => {
            if (response.data.status === "ok") {
                setFormState("success")
                socketStore.addNotification({
                    id: Date.now().toString(),
                    type: "message",
                    title: "notification.success",
                    message: "Save with success",
                })
                queryClient.invalidateQueries({ queryKey: ["player"] })
                userStore.player!.username = username
                return navigate("/games")
            } else {
                setFormState("error")
                setFormError(response.data.reason)
                socketStore.addNotification({
                    id: Date.now().toString(),
                    type: "error",
                    title: "notification.error",
                    message: "Error while saving",
                })
            }
        },
    })
    const [red, setRed] = useState(settingsStore.getFactionColor(Factions.RED))
    const [blue, setBlue] = useState(settingsStore.getFactionColor(Factions.BLUE))
    const [yellow, setYellow] = useState(settingsStore.getFactionColor(Factions.YELLOW))
    const [green, setGreen] = useState(settingsStore.getFactionColor(Factions.GREEN))


    const save = (event: FormEvent<HTMLFormElement>) => {
        setFormState("pending")
        setFormError("")
        event.preventDefault()
        changeUser.mutate()
        settingsStore.setAccessibilityMode(accessibilityMode)
        settingsStore.setFactionColor(Factions.RED, red.toString("hex"))
        settingsStore.setFactionColor(Factions.BLUE, blue.toString("hex"))
        settingsStore.setFactionColor(Factions.YELLOW, yellow.toString("hex"))
        settingsStore.setFactionColor(Factions.GREEN, green.toString("hex"))
    }

    const cancel = () => {
        return navigate(-1)
    }


    console.log(settingsStore.getFactionColors(Factions.YELLOW))
    return (
        <Box p="4">
            <form onSubmit={save}>
                <Heading my="4">Settings</Heading>

                <Text color="negative">{formError}</Text>

                {changeUser.isError && <Text color="negative">An error occurred. Please retry later.</Text>}

                <Field label="Username" helperText={userStore.player?.temporary ? "You need to be registered to change your username." : recentUserNameChange ? "You can't change your username more than once every 7 days." : "You can change your username once every 7 days."}>
                    <Input
                        placeholder="Username"
                        value={username}
                        required
                        maxLength={20}
                        minLength={4}
                        maxWidth={"md"}
                        color="orange.200"
                        bgColor="orange.700"
                        _focus={{ borderColor: "orange.500", boxShadow: "none" }}
                        disabled={userStore.player?.temporary || recentUserNameChange}
                        onChange={handleUsername}
                    />
                </Field>

                <Field my="4" helperText="Tick this to enable accessibility features. (Experimental)">
                    <Checkbox checked={accessibilityMode} onCheckedChange={handleAccessibility}>
                        Accessibility
                    </Checkbox>
                </Field>

                <Fieldset.Root>
                    <Fieldset.Legend>
                        <Text color="orange.100">Faction&apos;s colors</Text>
                    </Fieldset.Legend>
                    <Fieldset.HelperText mt="0" fontSize="xs">
                        You can change the colors of the factions to better suit your eyes. You should pick high contrast colors.
                    </Fieldset.HelperText>
                    <Fieldset.Content ms="4">
                        <Fieldset.Legend color="orange.100">Red</Fieldset.Legend>
                        <Group>
                            <Text fontSize="xs">Preview : </Text>
                            <ColorSwatch value={shadeColor(red.toString("hex"), 0.3)} />
                            <Text color={shadeColor(red.toString("hex"), 0.3)}>Player</Text>
                            <Text bgColor={shadeColor(red.toString("hex"), 0.3)} color={"goldenrod"} border="1px solid" borderColor="goldenrod" p="1px">Player</Text>
                            <Box bgColor={shadeColor(red.toString("hex"), 0.3)} p="2">Test</Box>
                        </Group>
                        <ColorPickerRoot
                            value={red}
                            format="hsla"
                            onValueChange={(e) => setRed(e.value)}
                            maxW="200px"
                        >
                            <ColorPickerControl>
                                <ColorPickerInput />
                                <ColorPickerTrigger />
                            </ColorPickerControl>
                            <ColorPickerContent>
                                <ColorPickerArea />
                                <HStack>
                                    <ColorPickerEyeDropper />
                                    <ColorPickerSliders />
                                </HStack>
                            </ColorPickerContent>
                        </ColorPickerRoot>

                        <Fieldset.Legend color="orange.100">Blue</Fieldset.Legend>
                        <Group>
                            <Text fontSize="xs">Preview : </Text>
                            <ColorSwatch value={shadeColor(blue.toString("hex"), 0.3)} />
                            <Text color={shadeColor(blue.toString("hex"), 0.3)}>Player</Text>
                            <Text bgColor={shadeColor(blue.toString("hex"), 0.3)} color={"goldenrod"} border="1px solid" borderColor="goldenrod" p="1px">Player</Text>
                            <Box bgColor={shadeColor(blue.toString("hex"), 0.3)} p="2">Test</Box>
                        </Group>
                        <ColorPickerRoot
                            value={blue}
                            format="hsla"
                            onValueChange={(e) => setBlue(e.value)}
                            maxW="200px"
                        >
                            <ColorPickerControl>
                                <ColorPickerInput />
                                <ColorPickerTrigger />
                            </ColorPickerControl>
                            <ColorPickerContent>
                                <ColorPickerArea />
                                <HStack>
                                    <ColorPickerEyeDropper />
                                    <ColorPickerSliders />
                                </HStack>
                            </ColorPickerContent>
                        </ColorPickerRoot>

                        <Fieldset.Legend color="orange.100">Yellow</Fieldset.Legend>
                        <Group>
                            <Text fontSize="xs">Preview : </Text>
                            <ColorSwatch value={shadeColor(yellow.toString("hex"), 0.3)} />
                            <Text color={shadeColor(yellow.toString("hex"), 0.3)}>Player</Text>
                            <Text bgColor={shadeColor(yellow.toString("hex"), 0.3)} color={"goldenrod"} border="1px solid" borderColor="goldenrod" p="1px">Player</Text>
                            <Box bgColor={shadeColor(yellow.toString("hex"), 0.3)} p="2">Test</Box>
                        </Group>
                        <ColorPickerRoot
                            value={yellow}
                            format="hsla"
                            onValueChange={(e) => setYellow(e.value)}
                            maxW="200px"
                        >
                            <ColorPickerControl>
                                <ColorPickerInput />
                                <ColorPickerTrigger />
                            </ColorPickerControl>
                            <ColorPickerContent>
                                <ColorPickerArea />
                                <HStack>
                                    <ColorPickerEyeDropper />
                                    <ColorPickerSliders />
                                </HStack>
                            </ColorPickerContent>
                        </ColorPickerRoot>

                        <Fieldset.Legend color="orange.100">Green</Fieldset.Legend>
                        <Group>
                            <Text fontSize="xs">Preview : </Text>
                            <ColorSwatch value={shadeColor(green.toString("hex"), 0.3)} />
                            <Text color={shadeColor(green.toString("hex"), 0.3)}>Player</Text>
                            <Text bgColor={shadeColor(green.toString("hex"), 0.3)} color={"goldenrod"} border="1px solid" borderColor="goldenrod" p="1px">Player</Text>
                            <Box bgColor={shadeColor(green.toString("hex"), 0.3)} p="2">Test</Box>
                        </Group>
                        <ColorPickerRoot
                            value={green}
                            format="hsla"
                            onValueChange={(e) => setGreen(e.value)}
                            maxW="200px"
                        >
                            <ColorPickerControl>
                                <ColorPickerInput />
                                <ColorPickerTrigger />
                            </ColorPickerControl>
                            <ColorPickerContent>
                                <ColorPickerArea />
                                <HStack>
                                    <ColorPickerEyeDropper />
                                    <ColorPickerSliders />
                                </HStack>
                            </ColorPickerContent>
                        </ColorPickerRoot>
                    </Fieldset.Content>
                </Fieldset.Root>


                <HStack justifyContent={"end"} my="4">
                    <Button onClick={() => cancel()} colorPalette="red">
                        Cancel
                    </Button>
                    <Button type="submit" loading={changeUser.isPending}>
                        Save
                    </Button>
                </HStack>
            </form>
        </Box>
    )
})

export default Settings
