import { useQuery } from "@tanstack/react-query"
import { EventType, Factions } from "../types"
import { authApi } from "./base"
import { Mod } from "./game"

export interface Event {
    id: number
    event_type: EventType
    start: string
    end: string | null
    faction: Factions
    /** flash event, no duration */
    flash: boolean
    permanent: boolean
    started: boolean
    ended: boolean
    faction_effects: {
        type: "fortification"
        bonus: number
    }[]
    effects: Mod[]

}
export async function getEvents(gameId: number): Promise<Event[]> {
    const events = await authApi.get(`game/${gameId}/events/list`)
    return events.data
}


export const useEvents = (gameId: number) => {
    const query = useQuery({ queryKey: ["events", gameId], queryFn: () => getEvents(gameId) })
    return query
}

export const useActiveEvents = (gameId: number) => {
    /** return currently active events */
    const events = useEvents(gameId)
    return events.data?.filter(e => e.started && !e.ended).map(e => e.event_type)
}