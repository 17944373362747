import { HStack, Text } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { Button } from "@/components/ui/button"
import { useStore } from "../../../store"
import { TileInfo } from "../../../store/MapStore"
import GameIcon, { GameIconType } from "../../common/GameIcon"

interface LeaderPanelProps {
    tileInfo: TileInfo
}
const LeaderPanel: FunctionComponent<LeaderPanelProps> = ({ tileInfo }) => {
    const { socketStore } = useStore()
    return (
        <>
            <div>Add a ping that lasts 30s on the map</div>
            <HStack justify={"center"}>
                <Button onClick={() => socketStore.leaderPing(tileInfo.x, tileInfo.y, "attack")}>
                    <HStack>
                        <GameIcon name={GameIconType.ATTACK} scale={1.3} />
                        <Text>Attack</Text>
                    </HStack>
                </Button>
                <Button onClick={() => socketStore.leaderPing(tileInfo.x, tileInfo.y, "defend")}>
                    <HStack>
                        <GameIcon name={GameIconType.DEFEND} scale={1.3} />
                        <Text>Defend</Text>
                    </HStack>
                </Button>
            </HStack>
        </>
    )
}

export default LeaderPanel
