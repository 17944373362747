import { defineRecipe } from "@chakra-ui/react";

export const panelRecipe = defineRecipe({
    base: {
        colorPalette: "orange",
        backgroundColor: 'colorPalette.500',
        p: 4,
        m: 4
    },
    variants: {
        visual: {
            smooth: {
                borderRadius: 'sm',
                boxShadow: 'md',
            },
            rounded: {
                borderRadius: 'xl',
                boxShadow: 'inset 6px 6px 10px 0px #00000080',
                borderColor: 'orange.200',
                borderWidth: '2px',
            },
            withBorder: {
                borderImage: 'url(/ui/border.png) 15 / 10px',
                borderImageRepeat: "stretch",
                borderRadius: "xl",
                boxShadow: "md",
            },
            badge: {
                p: 1,
                m: 1,
                borderRadius: 'sm',
                backgroundColor: 'orange.100',
                borderColor: 'orange.200',
                borderWidth: '1px',
                fontSize: "sm",
                boxShadow: "md",
                color: "black"
            },
            achievement: {
                p: 2,
                m: 1,
                // borderRadius: 'sm',
                color: 'orange.800',
                backgroundColor: 'orange.500',
                borderColor: 'orange.200',
                borderWidth: '1px',
                fontSize: "sm",
                transition: ".2s ease-out",
                boxShadow: "md",
                borderImage: 'url(/ui/border.png) 15 / 10px',
                borderImageRepeat: "stretch",
                borderRadius: "xl",
                _hover: {
                    backgroundColor: 'orange.400',
                    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.5)',
                    transform: 'translate3d(-2px,-2px,0)'
                }
            },
        },
    },
    defaultVariants: {
        visual: "smooth",
    },
})
