import { Box, HStack, Heading, Stack, Tabs, Text } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import Event from "./Event"
import { useEvents } from "../../api/event"
import { useGameId } from "../../hooks/query"



const Events: FunctionComponent = () => {
    const gameId = useGameId()
    const { data: events } = useEvents(gameId)

    const pastEvents = events?.filter(e => e.started && e.ended).sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime())
    const currentEvents = events?.filter(e => e.started && !e.ended).sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
    const upcomingEvents = events?.filter(e => !e.started).sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())

    return (
        <>
            <Tabs.Root lazyMount height="100%" overflowY="hidden" defaultValue="current">
                <Box display="flex" justifyContent="space-between" flexDirection={["column", "column", "row"]}>
                    <Heading as="h3" size="md" mb="2">
                        Events
                    </Heading>
                    <Tabs.List>
                        <Tabs.Trigger p='2' value="past">Past</Tabs.Trigger>
                        <Tabs.Trigger p='2' value="current">Current</Tabs.Trigger>
                        <Tabs.Trigger p='2' value="upcoming">Upcoming</Tabs.Trigger>
                    </Tabs.List>
                </Box>

                <Box height={["calc(100% - 5rem)", "calc(100% - 5rem)", "calc(100% - 2.5rem)"]} p="0" overflowY="auto">
                    <Tabs.Content value="past" p="0">
                        <Stack gap={1}>
                            {pastEvents?.map(e => <Event event={e} key={e.id} />)}
                        </Stack>
                    </Tabs.Content>
                    <Tabs.Content value="current" height="100%" p="0">
                        <Stack gap={1}>
                            {currentEvents?.map(e => <Event event={e} key={e.id} />)}
                        </Stack>
                    </Tabs.Content>
                    <Tabs.Content value="upcoming" height="100%" p="0">
                        <Stack gap={1}>
                            {upcomingEvents?.map(e => <Event event={e} key={e.id} />)}
                        </Stack>
                    </Tabs.Content>
                </Box>
            </Tabs.Root>
        </>
    )
}

export default Events
