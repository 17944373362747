import {
    Box,
    Center,
    Separator,
    Flex,
    HStack,
    Heading,
    IconButton,
    Stack,
    Text,
} from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent, useState } from "react"
import { useGame, useHQInfo, usePlayer } from "../../hooks/query"
import { useIsMobile } from "../../hooks/utils"
import { useStore } from "../../store"
import GatherProgress from "../GatherProgress"
import CurrentResources from "../resources/CurrentResources"
import ConnectedPlayers from "./header/Connected"
import ProfileButton from "./header/ProfileButton"
import Victory from "./header/Victory"
import {
    DrawerBackdrop,
    DrawerBody,
    DrawerCloseTrigger,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerRoot,
} from "@/components/ui/drawer"
import Link from "../common/Link"
import SleepMode from "./header/SleepMode"
import { RxHamburgerMenu } from "react-icons/rx";

const Header: FunctionComponent = observer(() => {
    const { settingsStore } = useStore()
    const isMobile = useIsMobile()

    // mobile drawer
    const [isOpen, setOpen] = useState(false)
    const player = usePlayer()

    const { data } = useHQInfo()
    const { data: game } = useGame()
    const sleepMode = data?.hq.sleepMode

    const factionColor = settingsStore.getFactionColors(data?.hq.faction).bg

    const bgColor = sleepMode ? "black" : factionColor

    return (
        <Box
            id="navigation"
            as="nav"
            role="navigation"
            w="full"
            color="orange.100"
            display="flex"
            flexDir="column"
            justifyContent={"space-between"}
            position="sticky"
            top="0"
            p="2"
            zIndex="2"
            bgColor={`${bgColor}`}
            transition="background-color 0.5s"
        >
            <Flex justify="space-between" onClick={() => isMobile && setOpen(true)}>
                {!isMobile && (
                    <>
                        <Stack gap="1">
                            <Heading fontSize="4xl">
                                <Link to={`/`} variant="transparent">
                                    Factions
                                </Link>
                            </Heading>
                            <Link href="https://discord.gg/DsKSmetnqV" target="_blank">
                                Join the discord !
                            </Link>
                        </Stack>
                        <Stack>
                            <Text as="span">{data?.hq.faction}</Text>
                            <ConnectedPlayers />
                        </Stack>
                    </>
                )}
                {isMobile && <IconButton size="sm" onClick={() => setOpen(true)} aria-label="Menu" variant="ghost"><RxHamburgerMenu /></IconButton>}
                <CurrentResources collapsable={false} simple={isMobile} />
                {!isMobile && (
                    <Box mr="2">
                        <SleepMode />
                    </Box>
                )}

                {!isMobile && <ProfileButton />}
            </Flex>

            <Box w="full">
                <GatherProgress />
            </Box>

            {game?.winning && <Victory />}

            {isMobile && (
                <DrawerRoot open={isOpen} onOpenChange={(e: any) => setOpen(e.open)}>
                    <DrawerBackdrop />
                    <DrawerContent bg={bgColor}>
                        <DrawerCloseTrigger />
                        <DrawerHeader>
                            <Link to="/">
                                Factions
                            </Link>
                        </DrawerHeader>
                        <DrawerBody>
                            <Stack>
                                <Link href="https://discord.gg/DsKSmetnqV" target="_blank">
                                    Join the discord !
                                </Link>
                                <HStack>
                                    <Text>Connected players : </Text>
                                    <ConnectedPlayers />
                                </HStack>
                                <Separator />
                                <SleepMode />
                                <Separator />
                                <Box flexGrow={1}>
                                    <CurrentResources collapsable={true} />
                                </Box>
                            </Stack>
                        </DrawerBody>
                        <DrawerFooter>
                            <Center>
                                <HStack gap="2">
                                    <Text>{player?.username}</Text>
                                    {/* <ProfileButton /> */}
                                </HStack>
                            </Center>
                        </DrawerFooter>
                    </DrawerContent>
                </DrawerRoot>
            )}

        </Box>
    )
})

export default Header
