import { FunctionComponent } from "react"
import Link from "./Link"
import { Factions } from "../../types"
import { LeaderRole } from "../../api/faction"
import { useStore } from "@/store"
import { observer } from "mobx-react-lite"

interface PlayerNameProps {
    playerId: number
    username: string
    faction?: Factions
    isPremium?: boolean
    leaderRole?: LeaderRole
}

const PlayerName: FunctionComponent<PlayerNameProps> = observer(({ playerId, username, faction, isPremium, leaderRole }) => {
    const { settingsStore } = useStore()
    const factionColor = settingsStore.getFactionColors(faction).username

    return (
        <Link to={`/player/${username}-${playerId}`} color={factionColor}>
            {username}
        </Link>
    )
})

export default PlayerName
