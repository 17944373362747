import { Box, Button, Collapsible, HStack, Icon, IconButton, Stack, Text } from "@chakra-ui/react"
import { FunctionComponent, useState } from "react"
import { useGame, useHQInfo } from "../../../hooks/query"
import DateComponent from "../../common/DateComponent"
import { IoMdArrowDropdown } from "react-icons/io"

const Victory: FunctionComponent = () => {
    const [isOpen, setOpen] = useState(false)
    const handleToggle = () => setOpen(!isOpen)

    const { data } = useHQInfo()
    const { data: game } = useGame()
    const faction = data?.hq.faction
    const winning = game?.winning
    const winner = faction === winning
    // get window width
    const showIcon = window.innerWidth < 1400
    return (
        <HStack
            w="full"
            bgColor={winner ? "green.600" : "red.600"}
            px="1"
            alignItems={"center"}
            justifyContent={"center"}
            onClick={handleToggle}
        >
            <Collapsible.Root open={isOpen} startingHeight={26}>
                <Collapsible.Content>
                    {faction === winning && (
                        <Text>
                            Your faction has captured the castle. Hold 3/4 of it until <DateComponent date={game!.winningDate} /> and
                            you&apos;ll win.
                        </Text>
                    )}
                    {faction !== winning && (
                        <Text>
                            {winning} faction has captured the castle. Take it back (at least 2 tiles) before{" "}
                            <DateComponent date={game!.winningDate} /> or you&apos;ll lose.
                        </Text>
                    )}
                </Collapsible.Content>
            </Collapsible.Root>
            {showIcon && <Icon aria-label="Show more"><IoMdArrowDropdown /></Icon>}
        </HStack>
    )
}

export default Victory
