import {
    Box,
    Center,
    HStack,
    Heading,
    SimpleGrid,
    Text,
    useDisclosure,
    IconButton,
} from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent, useEffect } from "react"
import Activities from "../components/activities/Activities"
import { Button } from "@/components/ui/button"
import Loading from "../components/common/Loading"
import Panel from "../components/common/Panel"
import EventProject from "../components/projects/EventProject"
import FinishedProject from "../components/projects/FinishedProject"
import PastProjects from "../components/projects/PastProjects"
import ProjectsTree from "../components/projects/ProjectsTree"
import { useProjects } from "../hooks/query"
import { useStore } from "../store"
import { MdFilterAlt } from "react-icons/md"
import { MenuContent, MenuItemGroup, MenuRadioItem, MenuRadioItemGroup, MenuRoot, MenuTrigger } from "@/components/ui/menu"
import {
    DialogActionTrigger,
    DialogBackdrop,
    DialogBody,
    DialogCloseTrigger,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogRoot,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import DevelopmentTree from "@/components/projects/DevelopmentTree"

const ProjectsActivities = observer(() => {
    const { projectsStore, settingsStore } = useStore()
    const filteredActivities = projectsStore.projectsActivities?.filter(
        (a) => a.amount === null || a.amount! >= settingsStore.projectsLogQuantity
    )
    return (
        <Panel>
            <HStack justify="space-between">
                <Heading as="h3" size="md" mb="2">
                    Logs
                </Heading>
                <MenuRoot size="sm" variant="solid">
                    <MenuTrigger as={Button} size="2xs" variant="outline" colorPalette="orange">
                        <MdFilterAlt />
                    </MenuTrigger>
                    <MenuContent>
                        <MenuItemGroup title="Quantity">
                            <MenuRadioItemGroup
                                value={"" + settingsStore.projectsLogQuantity}
                                onValueChange={(e: any) => settingsStore.setProjectsLogQuantity(parseInt(e.value as string, 10))}
                            >
                                <MenuRadioItem value={"1"}>All</MenuRadioItem >
                                <MenuRadioItem value={"5"}>{">"} 5</MenuRadioItem >
                                <MenuRadioItem value={"50"}>{">"} 50</MenuRadioItem >
                            </MenuRadioItemGroup >
                        </MenuItemGroup>
                    </MenuContent>
                </MenuRoot>
            </HStack>
            <Box p="1">
                <Activities
                    list={filteredActivities}
                    maxHeight="35rem"
                    initialized={projectsStore.projectsActivitiesInitialized}
                />
            </Box>
        </Panel>
    )
})

const Projects: FunctionComponent = observer(() => {
    const { data: projects, isLoading, isError } = useProjects()
    const events = projects?.event.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    const { userStore, projectsStore } = useStore()

    useEffect(() => {
        projectsStore.startProjectsSocket()
        return () => {
            projectsStore.stopProjectsSocket()
        }
    }, [userStore.player?.userId])

    return (
        <Box>
            {isLoading && <Loading />}
            {isError && <Box>Error</Box>}

            <HStack alignItems="flex-start" flexWrap="wrap">
                <Box flexGrow={1} flex={2}>
                    <Heading>Event projects</Heading>

                    <Text my="2">
                        Event projects are time-limited, and you are in competition with other factions. The first
                        faction get 100% of the bonus, the second 50% and the third 25%.
                    </Text>
                    <SimpleGrid columns={[1, 1, 1, 2]} gap={4}>
                        {events && events.map((project) => <EventProject {...project} key={project.id} />)}
                    </SimpleGrid>

                    <SimpleGrid columns={[1, 1, 1, 2]} gap={4} mt="4">
                        {projects?.last.map((project) => (
                            <FinishedProject {...project} key={project.id} />
                        ))}
                    </SimpleGrid>
                    <Center mt="2">
                        <DialogRoot lazyMount>
                            <DialogBackdrop />
                            <DialogTrigger asChild>
                                <Button title="Show all previous projects">
                                    Show all previous projects
                                </Button>
                            </DialogTrigger>
                            <DialogContent>
                                <DialogCloseTrigger />
                                <DialogHeader>
                                    <DialogTitle>Previous projects</DialogTitle>
                                </DialogHeader>
                                <DialogBody>
                                    <PastProjects />
                                </DialogBody>
                                <DialogFooter>
                                    <DialogActionTrigger asChild>
                                        <Button>Close</Button>
                                    </DialogActionTrigger>
                                </DialogFooter>
                            </DialogContent>
                        </DialogRoot>

                    </Center>
                </Box>
                <Box minWidth="20rem" flexGrow={1} flex={1} maxHeight="fit-content">
                    <ProjectsActivities />
                </Box>
            </HStack>

            <Heading>Development tree</Heading>
            <Text my="2">
                Each project will increase the cost for other projects. Bigger projects will have a bigger impact. So be careful with your choices and plan according to your faction&apos;s needs.
            </Text>
            <DevelopmentTree />
            {/* <ProjectsTree /> */}
        </Box>
    )
})

export default Projects
