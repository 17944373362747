import { Box, Heading, List, ListItem, Text } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { FaCheck } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import {Button} from "@/components/ui/button"
import { usePlayer } from "../hooks/query"

const RulesPage: FunctionComponent = () => {
    const navigate = useNavigate()

    return (
        <Box p="4">
            <Heading as="h1" mb="4">
                Guidelines
            </Heading>

            <List.Root pl="8">
                <List.Item>Be nice and tolerant: no racism, sexism, homophobia, insults, harassment, etc...</List.Item>
                <List.Item>No link to pornographic or violent content</List.Item>
                <List.Item>Only one account per player</List.Item>
                <List.Item>
                    No cheating. This includes :
                    <List.Root pl="8">
                        <List.Item>Spying on other players (projects, other faction chats, etc...)</List.Item>
                        <List.Item>Bots / automated scripts</List.Item>
                        <List.Item>Using exploits to get an advantage</List.Item>
                    </List.Root>
                </List.Item>
            </List.Root>

            <Text my="8">
                If you don&apos;t follow this simple rules, you could be forbidden from talking in the chat or simply be
                banned from the game.
            </Text>
        </Box>
    )
}

export default RulesPage
