import { Box, Span, Text } from "@chakra-ui/react"
import { FunctionComponent, memo } from "react"
import { ResourceType } from "../../types"
import GameIcon from "../common/GameIcon"
import Number from "../common/Number"

interface ResourceProps {
    name: ResourceType
    value: number
    diff?: number
    variant?: "negative" | "positive"
    animated?: boolean
}

const Resource: FunctionComponent<ResourceProps> = memo(({
    name,
    value,
    diff,
    variant,
    animated = false,
}: ResourceProps) => {
    return (
        <Span color={variant === "negative" ? "negative" : "positive"} whiteSpace={"nowrap"}>
            <Box pos="relative" top="3px" as="span">
                <GameIcon name={name} />
            </Box>
            <Number value={value} animated={animated} />
            {diff && (
                <>
                    ({diff > 0 && `+`}
                    <Number value={diff} animated={animated} />
                    )
                </>
            )}
        </Span>
    )
}, (prevProps, nextProps) => {
    return prevProps.name === nextProps.name && prevProps.value === nextProps.value && prevProps.diff === nextProps.diff && prevProps.variant === nextProps.variant && prevProps.animated === nextProps.animated
})

Resource.displayName = "Resource"

export default Resource
