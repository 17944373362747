import {
    Box,
    Card,
    CardBody,
    CardHeader,
    Center,
    Flex,
    HStack,
    Heading,
    Icon,
    Select,
    Text,
} from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { FunctionComponent, useState } from "react"
import { getLeaderboardPlayers } from "../../api/player"
import Loading from "../common/Loading"
import Progress from "../common/ProgressWithText"
import { useGameId } from "../../hooks/query"
import { FaCrown } from "react-icons/fa"
import { Tooltip } from "../ui/tooltip"
import {
    NativeSelectField,
    NativeSelectRoot,
} from "@/components/ui/native-select"
import { useStore } from "@/store"

type sortKeyType = "hqLevel" | "sentSoldiers" | "sentWorkers" | "casesCaptured"


export const Leaderboard: FunctionComponent = () => {
    const { settingsStore } = useStore()
    const gameId = useGameId()
    const { data: players, isLoading } = useQuery({
        queryKey: ["players", gameId],
        queryFn: () => getLeaderboardPlayers(gameId),
    })

    const [sortKey, setSortKey] = useState<sortKeyType>("hqLevel")

    const sorted = players
        ?.sort((a, b) => (a[sortKey] > b[sortKey] ? -1 : b[sortKey] > a[sortKey] ? 1 : 0))
        .slice(0, 100)

    return (
        <Card.Root variant="main">
            <Card.Header>
                <Heading size="md">Leaderboard</Heading>
            </Card.Header>

            <Card.Body>
                {isLoading && (
                    <Center>
                        <Loading />
                    </Center>
                )}
                <Box height={400} overflowY={"scroll"}>
                    <NativeSelectRoot
                        color="black"
                        bgColor="orange.300"
                        borderRadius={"sm"}
                        border="orange.900"
                    >
                        <NativeSelectField onChange={(e: any) => setSortKey(e.currentTarget.value as sortKeyType)} value={sortKey}>
                            <option value="hqLevel">HQ level</option>
                            <option value="sentSoldiers">Sent soldiers</option>
                            <option value="sentWorkers">Sent workers</option>
                            <option value="casesCaptured">Tiles captured</option>
                        </NativeSelectField>
                    </NativeSelectRoot>
                    {sorted &&
                        sorted.map((player, index) => {
                            return (
                                <Flex key={player.name} justifyContent="space-between" alignItems="center">
                                    <Progress
                                        value={player[sortKey]}
                                        total={sorted[0][sortKey]}
                                        h="50px"
                                        progressColor={settingsStore.getFactionColors(player.faction as unknown as Factions).bg}
                                        width="full"
                                    >
                                        <HStack justifyContent={"space-between"} alignItems={"center"}>
                                            <Text>
                                                {index + 1}. {player.name}
                                            </Text>
                                            <Text>{player[sortKey]}</Text>

                                            {player.awarded && (
                                                <Tooltip content="Best player Award">
                                                    <Box>
                                                        <Icon><FaCrown /></Icon>
                                                    </Box>
                                                </Tooltip>
                                            )}
                                        </HStack>
                                    </Progress>
                                </Flex>
                            )
                        })}
                </Box>
            </Card.Body>
        </Card.Root>
    )
}
