import { Box, Group } from "@chakra-ui/react"

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { FormEvent, FunctionComponent } from "react"
import { getHQInfo, updateRole } from "../../api/game"
import { useGameId } from "../../hooks/query"
import { Role } from "../../types"
import { Field } from "../ui/field"

import {
    NativeSelectField,
    NativeSelectRoot,
} from "@/components/ui/native-select"
import { Button } from "../ui/button"


const RoleSelect: FunctionComponent = () => {
    const gameId = useGameId()
    const queryClient = useQueryClient()
    const { data: hqInfo } = useQuery({ queryKey: ["hqInfo", gameId], queryFn: () => getHQInfo(gameId) })
    const mutation = useMutation({
        mutationFn: (role: Role) => updateRole(gameId, role),
        onSuccess: () => {
            // queryClient.setQueryData(["hqInfo", gameId], (oldData: HQInfo[] | undefined) => {})
            queryClient.invalidateQueries({ queryKey: ["hqInfo", gameId] })
            queryClient.invalidateQueries({ queryKey: ["factions", gameId] })
        },
    })

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const formDate = new FormData(e.currentTarget)
        const role = formDate.get("role")?.toString()
        mutation.mutate(role as Role)
    }
    return (
        <Box>
            <form onSubmit={(e) => handleSubmit(e)}>
                {hqInfo?.hq.level && (
                    <Group>
                        <Field label="Your role" helperText="Set your role so other players of your faction can see what's you're focusing on.">
                            <NativeSelectRoot>
                                <NativeSelectField name="role" textTransform="capitalize" defaultValue={hqInfo?.hq.role}>
                                    {Object.values(Role).map((role: any) => (
                                        <option key={role} value={role}>
                                            {role}
                                        </option>
                                    ))}
                                </NativeSelectField>
                            </NativeSelectRoot>
                        </Field>
                        <Button type="submit" size="sm">Ok</Button>
                    </Group>
                )}
            </form>
        </Box>
    )
}

export default RoleSelect
