import { action, makeObservable, observable } from "mobx"
import BaseStore from "./BaseStore"
import RootStore from "./RootStore"



export default class GameStore extends BaseStore {

    windowsFocus: boolean = true
    lastLostFocusDate: Date | null = null

    constructor(rootStore: RootStore) {
        super(rootStore)
        makeObservable(this, {
            windowsFocus: observable,
            lastLostFocusDate: observable,
            setLastLostFocusDate: action,
            setWindowsFocus: action
        })
        window.addEventListener("focus", () => {
            this.setWindowsFocus(true)
        })
        window.addEventListener("blur", () => {
            this.setWindowsFocus(false)
            this.setLastLostFocusDate(new Date())
        })
    }

    setLastLostFocusDate(date: Date) {
        this.lastLostFocusDate = date
    }

    setWindowsFocus(focus: boolean) {
        this.windowsFocus = focus
    }

}
