import { Box, Icon, Portal, Tabs, Text } from "@chakra-ui/react"
import hotkeys from "hotkeys-js"
import { observer } from "mobx-react-lite"
import { FunctionComponent, useEffect, useState } from "react"
import { BsChatSquareTextFill } from "react-icons/bs"
import { GiAchievement, GiCheckboxTree, GiWorld } from "react-icons/gi"
import { GoCheckbox } from "react-icons/go"
import { IoIosStats } from "react-icons/io"
import { RiHomeFill, RiTeamFill } from "react-icons/ri"
import { useLocation, useNavigate } from "react-router-dom"
import Lazy from "../components/common/Lazy"
import { useGameId, useIsUnlocked, usePlayer } from "../hooks/query"
import { useStore } from "../store"
import { getIGChannels } from "../store/ChatStore"
import { UnlockType } from "../types"
import AchievementPage from "./AchievementPage"
import ChatPage from "./ChatPage"
import FactionPage from "./FactionPage"
import HQ from "./HQ"
import Projects from "./ProjectsPage"
import SpecializationPage from "./SpecializationPage"
import Stats from "./Stats"
import World from "./World"

const ROUTES = {
    0: "hq",
    1: "world",
    2: "spec",
    3: "faction",
    4: "projects",
    5: "stats",
    6: "achievements",
    7: "chat",
}


const Menu: FunctionComponent = observer(() => {
    const showWorld = useIsUnlocked(UnlockType.UI_WORLD)
    const showProjects = useIsUnlocked(UnlockType.UI_PROJECTS)
    const showSpec = useIsUnlocked(UnlockType.UI_SPECIALIZATION)
    const { chatStore } = useStore()

    return (
        <Tabs.List id="main-menu" boxShadow={"0px -1px 10px black"}>
            <Tabs.Trigger aria-label="Village" value="hq">
                <Icon me="1"><RiHomeFill /></Icon>
                <Box display={["none", "none", "inline-block"]} as="span">
                    Village
                </Box>
            </Tabs.Trigger>
            <Tabs.Trigger aria-label="World map" display={(showWorld && "flex") || "none"} value="world">
                <Icon me="1"><GiWorld /></Icon>
                <Box display={["none", "none", "inline-block"]}>World</Box>
            </Tabs.Trigger>
            <Tabs.Trigger aria-label="Specialization" display={(showSpec && "flex") || "none"} value="spec">
                <Icon me="1"><GiCheckboxTree /></Icon>
                <Box display={["none", "none", "inline-block"]}>Spec</Box>
            </Tabs.Trigger>
            <Tabs.Trigger aria-label="Faction" display={(showWorld && "flex") || "none"} value="faction">
                <Icon me="1"><RiTeamFill /></Icon>
                <Box display={["none", "none", "inline-block"]}>Faction</Box>
            </Tabs.Trigger>
            <Tabs.Trigger aria-label="Projects" display={(showProjects && "flex") || "none"} value="projects">
                <Icon me="1"><GoCheckbox /></Icon>
                <Box display={["none", "none", "inline-block"]}>Projects</Box>
            </Tabs.Trigger>
            <Tabs.Trigger aria-label="Statistics" display={(showWorld && "flex") || "none"} value="stats">
                <Icon me="1"><IoIosStats /></Icon>
                <Box display={["none", "none", "inline-block"]}>Stats</Box>
            </Tabs.Trigger>
            <Tabs.Trigger aria-label="Achievements" value="achievements">
                <Icon me="1"><GiAchievement /></Icon>
                <Box display={["none", "none", "inline-block"]}>Achievements</Box>
            </Tabs.Trigger>
            <Tabs.Trigger aria-label="Chat" value="chat">
                <Icon me="1"><BsChatSquareTextFill /></Icon>
                <Box display={["none", "none", "inline-block"]}>Chat</Box>
                {chatStore.hasUnreadMessages() && (
                    <Box
                        display={"inline-block"}
                        borderRadius="50%"
                        width="8px"
                        height="8px"
                        bgColor="red.800"
                        position="relative"
                        top="-6px"
                        left="0"
                    ></Box>
                )}
            </Tabs.Trigger>
        </Tabs.List>
    )
})

const Game: FunctionComponent = observer(() => {
    // set current tab according to url
    const { chatStore } = useStore()
    const { pathname } = useLocation()
    const player = usePlayer()
    const navigate = useNavigate()
    const [value, setValue] = useState("hq")
    const gameId = useGameId()

    const showWorld = useIsUnlocked(UnlockType.UI_WORLD)
    const showProjects = useIsUnlocked(UnlockType.UI_PROJECTS)
    const showSpec = useIsUnlocked(UnlockType.UI_SPECIALIZATION)


    const handleTabsChange = (tab: any) => {
        setValue(tab.value)
        navigate(`/play/${gameId}/${tab.value}/`)
    }

    const location = useLocation()
    useEffect(() => {
        const lastPart = location.pathname.split("/")[3]
        setValue(lastPart)
    }, [location])

    // start chat even if we're not on the chat itself
    useEffect(() => {
        if (player) {
            const channels = getIGChannels(player)
            channels.forEach((channel) => {
                chatStore.initChannel(channel.id, channel.name)
            })
        }

        return () => {
            if (player) {
                const channels = getIGChannels(player)
                channels.forEach((c) => {
                    chatStore.leaveChannel(c.id)
                })
            }
        }
    }, [player?.userId])

    useEffect(() => {
        hotkeys("1,2,3,4,5,6,7,8", function (event, handler) {
            event.preventDefault()
            const index = parseInt(handler.key) - 1
            if (handler.shortcut === "2" && !showWorld) return
            if (handler.shortcut === "3" && !showSpec) return
            if (handler.shortcut === "4" && !showProjects) return
            setValue(ROUTES[index as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7])
            navigate(`/play/${gameId}/${ROUTES[index as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7]}/`)
        })
        return () => {
            hotkeys.unbind()
        }
    }, [showWorld, showProjects, showSpec])


    return (
        <Box>
            <Tabs.Root value={value} variant="main" lazyMount onValueChange={handleTabsChange}>
                {/* {!isMobile && <Menu />} */}

                <Tabs.Content value="hq" padding="4">
                    <HQ />
                </Tabs.Content>

                <Tabs.Content value="world" padding="0">{showWorld && <World />}</Tabs.Content>

                <Tabs.Content value="spec" padding="4">{showSpec && <SpecializationPage />}</Tabs.Content>

                <Tabs.Content value="faction" padding="4">
                    <FactionPage />
                </Tabs.Content>

                <Tabs.Content value="projects" padding="4">
                    {showProjects && (
                        <Lazy active={value === "projects"}>
                            <Projects />
                        </Lazy>
                    )}
                </Tabs.Content>
                <Tabs.Content value="stats" padding="4">
                    <Stats />
                </Tabs.Content>
                <Tabs.Content value="achievements" padding="4">
                    <Lazy active={value === "achievements"}>
                        <AchievementPage />
                    </Lazy>
                </Tabs.Content>
                <Tabs.Content value="chat" padding="4">
                    <ChatPage />
                </Tabs.Content>

                {/* {isMobile && ( */}
                <Portal>
                    <Box position={"fixed"} bottom="0" left="0" bgColor="orange.300" w="full">
                        <Menu />
                    </Box>
                </Portal>
                {/* )} */}
            </Tabs.Root>
        </Box>
    )
})

export default Game
