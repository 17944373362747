import GameStore from "./GameStore"
import UserStore from "./UserStore"
import MapStore from "./MapStore"
import SocketStore from "./SocketStore"
import ProjectsStore from "./ProjectsStore"
import ChatStore from "./ChatStore"
import SettingsStore from "./SettingsStore"

export default class RootStore {
  gameStore: GameStore
  userStore: UserStore
  mapStore: MapStore
  socketStore: SocketStore
  projectsStore: ProjectsStore
  chatStore: ChatStore
  settingsStore: SettingsStore

  constructor() {
    this.gameStore = new GameStore(this)
    this.userStore = new UserStore(this)
    this.mapStore = new MapStore(this)
    this.socketStore = new SocketStore(this)
    this.projectsStore = new ProjectsStore(this)
    this.chatStore = new ChatStore(this)
    this.settingsStore = new SettingsStore(this)



  }


}



