import { LinkProps } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { Link as ReachLink } from "react-router-dom"
import { Link as ChakraLink } from "@chakra-ui/react"

interface MyLinkProps extends LinkProps {
    to?: string
    href?: string
}

const Link: FunctionComponent<MyLinkProps> = ({ to, href, children, ...props }) => {
    if (href) return <ChakraLink href={href} {...props}>{children}</ChakraLink>
    return <ChakraLink {...props} asChild><ReachLink to={to!}>{children}</ReachLink></ChakraLink>
}

export default Link
