import { Box, Button, Group, Stack, Text } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { FunctionComponent } from "react"
import { Choice as ChoiceInterface, Survey, vote } from "../../api/faction"
import { useGameId, usePlayer } from "../../hooks/query"
import Progress from "../common/ProgressWithText"
import { useVote } from "./hooks"
import ModList from "../resources/ModList"

interface ChoiceProps extends ChoiceInterface {
    surveyId: number
    surveyType: string
    pickUsers: boolean
    totalVotes: number
    voted: boolean
}
const Choice: FunctionComponent<ChoiceProps> = ({
    surveyId,
    surveyType,
    id,
    info,
    name,
    votes,
    totalVotes,
    voted,
    pickUsers,
    winner,
}) => {
    const gameId = useGameId()
    const player = usePlayer()
    const canVote = !pickUsers || id != player?.userId
    const voteMutation = useVote({ gameId, surveyId, choiceId: id, invalidateOnVote: surveyType === "Map" })
    return (
        <Progress
            value={votes}
            total={totalVotes}
            minHeight={8}
            onClick={() => {
                if (canVote) voteMutation.mutate({})
            }}
            cursor={canVote ? "pointer" : "not-allowed"}
            border={winner ? "2px solid green" : "none"}
            _hover={{
                border: "2px solid",
                borderColor: "orange.600",
            }}
            childrenBoxProps={{
                justifyContent: info && 'effects' in info ? "space-between" : "center",
            }}
            width="full"
        >
            <Group justify="space-between" align="center">
                <Text>{name} - {votes} {voted && "✔"}</Text>
                {info && 'effects' in info ? <Text fontSize="xs"><ModList value={info.effects} /></Text> : null}
            </Group>
        </Progress>
    )
}

export default Choice
