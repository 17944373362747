import { IoIosHelpCircleOutline } from "react-icons/io";

import { Icon, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import {
    PopoverBody,
    PopoverContent,
    PopoverRoot,
    PopoverTitle,
    PopoverTrigger,
} from "@/components/ui/popover"
import { useIsMobile } from "../../hooks/utils";
import { Tooltip } from "@/components/ui/tooltip"


interface HelpTextPropsProps {
    children?: React.ReactElement | string
    tooltip: React.ReactElement | string
    showHelpButton?: boolean
    popoverForMobile?: boolean /** render as a popover instead of tooltip */
}
const HelpText: FunctionComponent<HelpTextPropsProps> = ({
    children, tooltip, showHelpButton = true, popoverForMobile = true
}) => {
    const isMobile = useIsMobile()
    if (popoverForMobile && isMobile) {
        return (
            <PopoverRoot>
                <PopoverTrigger>
                    <Text as="span">{children} {showHelpButton && <Icon top="-2px" position="relative"><IoIosHelpCircleOutline size="1.5rem"/></Icon>}</Text>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverBody>{tooltip}</PopoverBody>
                </PopoverContent>
            </PopoverRoot>
        )
    }
    return (
        <Tooltip content={tooltip}>
            <Text as="span">{children} {showHelpButton && <Icon top="-2px" position="relative"><IoIosHelpCircleOutline size="1.5rem"/></Icon>}</Text>
        </Tooltip>
    )
}

export default HelpText
