import { defineSlotRecipe } from "@chakra-ui/react"


export const dialogRecipe = defineSlotRecipe({
    slots: ['backdrop', 'content', 'positioner', 'header', 'body', 'footer', 'title', 'description', 'close-trigger'],
    // define the part you're going to style
    base: {
        content: {
            backgroundColor: "orange.600",
            color: "orange.200",
            // boxShadow: "none",
            // borderRadius: "sm",
            borderImage: 'url(/ui/border.png) 15 / 10px',
            borderImageRepeat: "stretch",
            borderRadius: "xl",
            boxShadow: "md",
            // borderImageWidth: "10px 10px 0px 10px",

            '&  [data-part=close-trigger]': {
                color: "orange.200",
                marginTop: "0.6rem",
                _hover: {
                    color: "orange.50",
                },
                '& svg': {
                    width: "2rem",
                    height: "2rem",
                }
            }
        },
        header: {
            borderImage: 'url(/ui/border.png) 15 / 10px',
            borderImageRepeat: "stretch",
            borderRadius: "xl",
            borderImageWidth: "10px 10px 0px 10px",
            bgColor: "red.800",
            borderBottomRadius: "none",
        },
    }


})

