import { defineRecipe } from "@chakra-ui/react";


export const buttonRecipe = defineRecipe({
    className: "chakra-button",
    base: {
      display: "inline-flex",
      appearance: "none",
      alignItems: "center",
      justifyContent: "center",
      userSelect: "none",
      position: "relative",
      borderRadius: "l2",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      borderWidth: "1px",
      borderColor: "transparent",
      cursor: "button",
      flexShrink: "0",
      outline: "0",
      lineHeight: "1.2",
      isolation: "isolate",
      fontWeight: "medium",
      transitionProperty: "common",
      transitionDuration: "moderate",
      focusVisibleRing: "outside",
      _disabled: {
        layerStyle: "disabled",
      },
      _icon: {
        flexShrink: "0",
      },
      colorPalette: "green",
      '& > span': {
        display: "inline-flex",
        appearance: "none",
        alignItems: "center",
        justifyContent: "center",
        gap: "1",
      }
    },
  
    variants: {
      size: {
        "2xs": {
          h: "6",
          minW: "6",
          textStyle: "xs",
          px: "2",
          gap: "1",
          _icon: {
            width: "3.5",
            height: "3.5",
          },
        },
        xs: {
          h: "9",
          minW: "8",
          textStyle: "xs",
          px: "2.5",
          gap: "1",
          _icon: {
            width: "4",
            height: "4",
          },
        },
        sm: {
          h: "10",
          minW: "9",
          px: "3.5",
          textStyle: "sm",
          gap: "2",
          _icon: {
            width: "4",
            height: "4",
          },
        },
        md: {
          h: "12",
          minW: "10",
          textStyle: "md",
          px: "4",
          gap: "2",
          _icon: {
            width: "5",
            height: "5",
          },
        },
        lg: {
          h: "14",
          minW: "11",
          textStyle: "md",
          px: "5",
          gap: "3",
          _icon: {
            width: "5",
            height: "5",
          },
        },
        xl: {
          h: "16",
          minW: "12",
          textStyle: "md",
          px: "5",
          gap: "2.5",
          _icon: {
            width: "5",
            height: "5",
          },
        },
        "2xl": {
          h: "18",
          minW: "16",
          textStyle: "lg",
          px: "7",
          gap: "3",
          _icon: {
            width: "6",
            height: "6",
          },
        },
      },
  
      variant: {
        solid: {
            borderWidth: "3px",
            borderColor: "black",
            borderRadius: "none",
            backgroundColor: "colorPalette.solid",
            color: "colorPalette.contrast",
            fontWeight: "normal",
            transition: "all 0.1s ease",
            _hover: {
                _disabled: {
                    borderStyle: "outset",
                    bgColor: `colorPalette.emphasized`,
                },
                borderStyle: "inset",
                borderRadius: "none",
                borderColor: "black",
                _before: {
                    border: "2px solid",
                    borderColor: 'colorPalette.emphasized',
                    //  #788f6d",
                    borderStyle: "outset",
                    borderRadius: "none",
                },
                bg: "colorPalette.solid",
            },

            _before: {
                transition: "all 0.1s ease",
                content: '" "',
                position: "absolute",
                // zIndex: -1,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                border: "4px solid",
                borderColor: 'colorPalette.emphasized',
                borderStyle: "outset",
                borderRadius: "none",
            },
            bg: "colorPalette.solid",
            _expanded: {
                bg: "colorPalette.solid/90",
            },
        },
  
        subtle: {
          bg: "colorPalette.subtle",
          color: "colorPalette.fg",
          _hover: {
            bg: "colorPalette.muted",
          },
          _expanded: {
            bg: "colorPalette.muted",
          },
        },
  
        surface: {
          bg: "colorPalette.subtle",
          color: "colorPalette.fg",
          shadow: "0 0 0px 1px var(--shadow-color)",
          shadowColor: "colorPalette.muted",
          _hover: {
            bg: "colorPalette.muted",
          },
          _expanded: {
            bg: "colorPalette.muted",
          },
        },
  
        outline: {
          borderWidth: "1px",
          borderColor: "colorPalette.muted",
          color: "colorPalette.fg",
          _hover: {
            bg: "colorPalette.subtle",
          },
          _expanded: {
            bg: "colorPalette.subtle",
          },
        },
        ghost: {
          color: "colorPalette.fg",
          _hover: {
            bg: "transparent",
          },
          _expanded: {
            bg: "colorPalette.subtle",
          },
        },
  
        plain: {
          color: "colorPalette.fg",
        },
      },
    },
  
    defaultVariants: {
      size: "md",
      variant: "solid",
    },
  })

// export const buttonRecipe = defineRecipe({
//     base: {
//         colorPalette: "green",
        
//     },
//     variants: {
//         visual: {
//             solid: {
//                 border: "3px solid",
//                 borderColor: "black",
//                 borderRadius: "none",
//                 backgroundColor: "colorPalette.solid",
//                 color: "colorPalette.contrast",
//                 margin: "4px",
//                 fontWeight: "normal",
//                 transition: "all 0.1s ease",
//                 _hover: {
//                     _disabled: {
//                         borderStyle: "outset",
//                         bgColor: `colorPalette.700`,
//                     },
//                     borderStyle: "inset",
//                     borderRadius: "none",
//                     borderColor: "black",
//                     _before: {
//                         border: "2px solid",
//                         borderColor: 'colorPalette.600',
//                         //  #788f6d",
//                         borderStyle: "outset",
//                         borderRadius: "none",
//                     },
//                     bg: "colorPalette.solid",
//                 },

//                 _before: {
//                     transition: "all 0.1s ease",
//                     content: '" "',
//                     position: "absolute",
//                     // zIndex: -1,
//                     top: 0,
//                     left: 0,
//                     right: 0,
//                     bottom: 0,
//                     border: "4px solid",
//                     borderColor: 'colorPalette.600',
//                     borderStyle: "outset",
//                     borderRadius: "none",
//                 },
//                 bg: "colorPalette.solid",
//                 _expanded: {
//                     bg: "colorPalette.solid/90",
//                 },
//             },
//         },
//         size: {
//             xl: {
//                 fontSize: "lg",
//                 px: 25,
//                 py: 3,
//             },
//         },
//     },
//     defaultVariants: {
//         visual: "solid",
//     },
// })

// const variantGhost = defineStyle((props) => {
//     const { colorPalette: c, theme } = props


//     if (c === "gray") {
//         return {
//             color: mode(`gray.800`, `whiteAlpha.900`)(props),
//             _hover: {
//                 bg: mode(`gray.100`, `whiteAlpha.200`)(props),
//             },
//             _active: { bg: mode(`gray.200`, `whiteAlpha.300`)(props) },
//         }
//     }

//     const darkHoverBg = transparentize(`${c}.200`, 0.12)(theme)
//     const darkActiveBg = transparentize(`${c}.200`, 0.24)(theme)

//     return {
//         color: mode(`${c}.600`, `${c}.200`)(props),
//         bg: "transparent",
//         _hover: {
//             bg: mode(`${c}.50`, darkHoverBg)(props),
//         },
//         _active: {
//             bg: mode(`${c}.100`, darkActiveBg)(props),
//         },
//     }
// })

// https://github.com/chakra-ui/chakra-ui/blob/6d12ea81f4791f4df2953533b570178f59551b74/packages/components/theme/src/components/button.ts#L4


// const variantBase = defineStyle((props) => {
//     const color = props.colorPalette || "green"
//     const backgroundColor = ["black", "white"].includes(color) ? color : `${color}.700`
//     const borderColor = ["black", "white"].includes(color) ? color : `${color}.600`
//     return {
//         border: "3px solid",
//         borderColor: "black",
//         borderRadius: "sm",
//         backgroundColor: backgroundColor,
//         color: "orange.100",
//         margin: "4px",
//         fontWeight: "normal",
//         transition: "all 0.1s ease",

//         _hover: {
//             _disabled: {
//                 borderStyle: "outset",
//                 bgColor: `${color}.700`,
//             },
//             borderStyle: "inset",
//             borderRadius: "none",
//             _before: {
//                 border: "2px solid",
//                 borderColor: borderColor,
//                 //  #788f6d",
//                 borderStyle: "outset",
//                 borderRadius: "none",
//             },
//         },

//         _before: {
//             transition: "all 0.1s ease",
//             content: '" "',
//             position: "absolute",
//             // zIndex: -1,
//             top: 0,
//             left: 0,
//             right: 0,
//             bottom: 0,
//             border: "4px solid",
//             borderColor: borderColor,
//             borderStyle: "outset",
//             borderRadius: "sm",
//         },

//     }
// })


// export const Button = defineStyleConfig({
//     baseStyle: {

//     },
//     variants: {
//         base: variantBase,
//         negative: {
//             color: "orange.100",
//             bgColor: "red.800",
//             borderColor: "red.900",
//             _before: {
//                 borderColor: "red.600",
//             },
//             _hover: {
//                 borderColor: "red.900",
//                 _before: {
//                     borderColor: "red.600",
//                 },
//             },
//             // linear-gradient(0deg, #650113 0%, #ca0402 100%)
//         },
//         ghost: {
//             color: "orange.100",
//             border: "2px solid",
//             borderColor: "orange.600",
//             borderRadius: "sm",
//             bgColor: "transparent",
//             _hover: {
//                 borderColor: "transparent",
//                 color: "black",
//             },
//             _active: {
//                 color: "black"
//             }
//         },
//     },
//     defaultProps: {
//         variant: 'base',
//         colorPalette: 'green'
//     }
// })