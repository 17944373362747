import ReconnectingWebSocket  from "reconnecting-websocket";
import { Network } from "vis-network/peer";

declare global {
    interface Window {
        worldSocket: ReconnectingWebSocket | null;
        game: Phaser.Game | null;
        network: Network | null;
    }

    interface Date {
        addHours: (hours: number) => Date
    }
}


export enum ResourceType {
    WOOD = "wood",
    IRON = "iron",
    SOLDIER = "soldiers",
    WORKER = "workers",
    GUARDIAN = "guardian",
    KNIGHT = "knight",
    TICKS = "ticks",
    VICTORY_POINTS = "victoryPoints"
}
export enum PlayerResourcesType {
    WOOD = "wood",
    IRON = "iron",
    SOLDIER = "soldiers",
    WORKER = "workers",
    GUARDIAN = "guardian",
    KNIGHT = "knight",
    TICKS = "ticks"
}
export type ResourceCost = {
    [key in PlayerResourcesType]?: number
}

export enum SupportType {
    GUARDIAN = "guardian",
    KNIGHT = "knight"
}

export type PlayerResources = {
    [key in PlayerResourcesType]: number
}

export type ResourcesList = {
    [key in ResourceType]?: number
}


export enum BuildingType {
    WOODCUTTER = "WOODCUTTER",
    MINE = "MINE",
    STORAGE = "STORAGE",
    BARRACK = "BARRACK",
    TRAINING_CENTER = "TRAINING_CENTER",
    TAVERN = "TAVERN",
    COMMAND_CENTER = "COMMAND_CENTER",
    KNIGHT_TRAINING_CENTER = "KNIGHT_TRAINING_CENTER",
    GUARDIAN_TRAINING_CENTER = "GUARDIAN_TRAINING_CENTER",

}

export enum SpecializationType {
    ATTACK = "ATTACK",
    DEFENSE = "DEFENSE",
    LOGISTIC = "LOGISTIC"
}

export enum Factions {
    RED = "RED",
    GREEN = "GREEN",
    BLUE = "BLUE",
    YELLOW = "YELLOW",
    NEUTRAL = "NEUTRAL"
}

export const FACTIONS_LIST = [Factions.RED, Factions.GREEN, Factions.BLUE, Factions.YELLOW]

export enum FactionsWithoutNeutral {
    RED = "RED",
    GREEN = "GREEN",
    BLUE = "BLUE",
    YELLOW = "YELLOW",
}

export enum TerrainType {
    WOOD = "wood",
    WATER = "water",
    MOUNTAIN1 = "mountain1",
    MOUNTAIN2 = "mountain2",
    HILL = "hill",
    GRASS = "grass",
    DIRT = "dirt",
    LEAVES = "leaves",
    ROAD = "road",
    DEEP_WATER = "deep_water",
    HQ_RED = "hq_red",
    HQ_BLUE = "hq_blue",
    HQ_GREEN = "hq_green",
    HQ_YELLOW = "hq_yellow",
    CASTLE = "castle",
}
export interface UserBuilding {
    id: number
    name: BuildingType
    level: number
    upgradeCost: ResourceCost
}


export enum UnlockType {
    UI_WORLD = "UI_WORLD",
    UI_PROJECTS = "UI_PROJECTS",
    UI_SPECIALIZATION = "UI_SPECIALIZATION",
    UI_GUARDIAN = "UI_GUARDIAN",
    UI_KNIGHT = "UI_KNIGHT",
    UI_TILE_IMPROVEMENT = "UI_TILE_IMPROVEMENT",
    UI_TILE_FORTIFICATION = "UI_TILE_FORTIFICATION",

    COMMAND_CENTER = "COMMAND_CENTER",
    KNIGHT_TRAINING_CENTER = "KNIGHT_TRAINING_CENTER",
    GUARDIAN_TRAINING_CENTER = "GUARDIAN_TRAINING_CENTER",
}


export type FactionStat = {
    faction: Factions
    percentage: number
    value: number
}

export enum ProjectType {
    ATTACK = "ATTACK",
    DEFENSE = "DEFENSE",
    TILE_FORTIFICATION = "TILE_FORTIFICATION",
    GUARDIAN_POWER = "GUARDIAN_POWER",
    SOLDIER_STORAGE = "SOLDIER_STORAGE",
    SOLDIER_PRODUCTION = "SOLDIER_PRODUCTION",
    KNIGHT_POWER = "KNIGHT_POWER",
    SUPPORT_POWER = "SUPPORT_POWER",
    SOLDIER_BONUS = "SOLDIER_BONUS",
    WORKER_EFFICENCY = "WORKER_EFFICENCY",
    WOOD_IRON_STORAGE = "WOOD_IRON_STORAGE",
    WOOD_IRON_PRODUCTION = "WOOD_IRON_PRODUCTION",
    STORAGE = "STORAGE",
    WORKER_PRODUCTION = "WORKER_PRODUCTION",
    TILE_IMPROVEMENT = "TILE_IMPROVEMENT",
    BARRACK = "BARRACK"
}



export enum Role {
    SOLDIER = "soldier",
    WORKER = "worker",
    HYBRID = "hybrid",
    ECONOMY = "economy",
}


export enum EventType {
    // seasons
    SPRING = "SPRING",
    SUMMER = "SUMMER",
    FALL = "FALL",
    WINTER = "WINTER",
    // other events
    UPGRADE_COST_RANDOMIZER = "UPGRADE_COST_RANDOMIZER",
    LOW_TIDE = "LOW_TIDE",
    ANCIENT_TREES = "ANCIENT_TREES",
    RICH_MINES = "RICH_MINES",
    TRADE_ROUTE = "TRADE_ROUTE",
    PLUNDER_FEST = "PLUNDER_FEST",
    LOSING_FACTIONS_SOLDIER_BONUS = "LOSING_FACTIONS_SOLDIER_BONUS",
    LOSING_FACTIONS_DEFENSE_BONUS = "LOSING_FACTIONS_DEFENSE_BONUS",
    WORKERS_ATTACK = "WORKERS_ATTACK",
    NEUTRAL_INVASIONS = "NEUTRAL_INVASIONS",
    STORAGE_BOOST = "STORAGE_BOOST",
    EARTHQUAKE = "EARTHQUAKE",
    FRENZY = "FRENZY",
    
}