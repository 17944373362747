import { Box, Button, Group, HStack, Input, Text } from "@chakra-ui/react"
import { FunctionComponent, useState } from "react"
import { usePlayer } from "../../hooks/query"
import { observer } from "mobx-react-lite"
import { useStore } from "../../store"
import { Checkbox } from "../ui/checkbox"
import { Field } from "../ui/field"
import { Slider } from "../ui/slider"

const MapUI: FunctionComponent = observer(() => {
    const { settingsStore } = useStore()

    const player = usePlayer()
    const bgColor = settingsStore.getFactionColors(player?.faction).bg

    return (
        <Box id="map-ui" bg={bgColor} p="1">
            <Group alignItems={"center"} gap="8">
                <Field>
                    <Checkbox
                        checked={settingsStore.showMapSoldiers}
                        onCheckedChange={(details) => settingsStore.setShowMapSoldiers(!!details.checked)}
                    >
                        Show soldiers
                    </Checkbox>
                </Field>

                <Field label="Tile opacity" orientation="horizontal" whiteSpace="nowrap">
                    <Slider
                        width={["90px", "140px", "200px"]}
                        value={[settingsStore.tileOpacity]}
                        onValueChange={(details) => settingsStore.setTileOpacity(details.value[0])}
                    />
                </Field>

            </Group>
        </Box>
    )
})

export default MapUI
