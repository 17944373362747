import { Box, Button, Image, Text } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { FunctionComponent } from "react"
import { Choice as ChoiceInterface, Survey, vote } from "../../api/faction"
import { useGameId, usePlayer } from "../../hooks/query"
import Progress from "../common/ProgressWithText"
import { useVote } from "./hooks"

interface MapChoiceProps extends ChoiceInterface {
    surveyId: number
    pickUsers: boolean
    totalVotes: number
    voted: boolean
}
const MapChoice: FunctionComponent<MapChoiceProps> = ({
    surveyId,
    id,
    bonus,
    name,
    info,
    votes,
    totalVotes,
    voted,
    pickUsers,
    winner,
}) => {
    const gameId = useGameId()
    const player = usePlayer()
    const canVote = !pickUsers || id != player?.userId
    const voteMutation = useVote({ gameId, surveyId, choiceId: id, invalidateOnVote: true })

    return (
        <Box
            margin="1"
            padding="1"
            onClick={() => {
                if (canVote) voteMutation.mutate({})
            }}
            _hover={{
                border: "2px solid",
                borderColor: "orange.600",
            }}
            cursor={canVote ? "pointer" : "auto"}
            border={winner ? "2px solid green" : "none"}
        >
            <Progress value={votes} total={totalVotes} height={8}>
                {name} - {votes} {voted && "✔"}
            </Progress>
            <Box border="2px solid" borderColor="orange.600" position="relative">
                <Image src={`/map/${name.toLowerCase()}.png`} />
                <Text position="absolute" right="0" bottom="0px" p="0.5" backgroundColor="orange.600" fontSize="xs">
                    Creator: {info?.author}
                </Text>
            </Box>

            <Text fontSize="sm" mt="1">
                {info?.description}
            </Text>
        </Box>
    )
}

export default MapChoice
