import { HStack, Table, Text } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { Role } from "../../types"
import Link from "../common/Link"
import { default as RoleComponent } from "../common/Role"

interface PlayerProps {
    id: number
    name: string
    role: Role
    hq_level: number
    spec: string
}
const Player: FunctionComponent<PlayerProps> = ({ id, name, hq_level, role, spec }) => {
    return (
        <Table.Row bgColor="orange.600">
            <Table.Cell>
                <Link to={`/player/${name}-${id}`}>
                    {name}
                </Link>
            </Table.Cell>
            <Table.Cell>{hq_level}</Table.Cell>
            <Table.Cell>
                <RoleComponent name={role} />
            </Table.Cell>
            <Table.Cell>{spec}</Table.Cell>
        </Table.Row>
    )
}

export default Player
