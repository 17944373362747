import { Tooltip } from "@/components/ui/tooltip"
import {
    Box,
    Button,
    Card,
    Center,
    HStack,
    Image,
    SimpleGrid,
    Stack,
    Text
} from "@chakra-ui/react"
import { useMutation } from "@tanstack/react-query"
import { observer } from "mobx-react-lite"
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { Project as ProjectInterface, work } from "../../api/projects"
import { useConfig, useGameId, useProjects, useResources } from "../../hooks/query"
import { UISpendResources, useIsMobile } from "../../hooks/utils"
import { useStore } from "../../store"
import { BuildingType, ResourceType } from "../../types"
import GameIcon from "../common/GameIcon"
import Progress from "../common/ProgressWithText"
import ModList from "../resources/ModList"
import Resources from "../resources/Resources"

interface ProjectProps {
    id: number
}

const Project: FunctionComponent<ProjectProps> = observer(({ id }) => {
    const { projectsStore } = useStore()
    const gameId = useGameId()
    const { data: projects, isLoading, isError } = useProjects()
    const config = useConfig()
    const project = projects?.permanent.find((p: ProjectInterface) => p.id === id)
    const { t } = useTranslation()

    const resources = useResources()
    const { userStore } = useStore()
    const isMobile = useIsMobile()

    // console.log("render project", id)

    // send workers
    const workMutation = useMutation({
        mutationFn: (workers: number) => work(gameId, id, workers),

        onMutate: (workers) => {
            // UISpendResources({ queryClient, resources: { workers: workers } })
        },
        onSuccess: (data) => {
            UISpendResources({ gameId, resources: { workers: data.workersSent } })
            // handled by socket
            // queryClient.invalidateQueries(["projects"])
        },
    })

    if (!project) {
        return <Box>No project</Box>
    }

    const buildingInfo =
        project.type === "building"
            ? config?.buildings.find((building) => building.name == (project.name as unknown as BuildingType))
            : null



    return (
        <Card.Root
            variant={isMobile ? "outline" : "main"}
            color="orange.100"
            m={isMobile ? "0" : "2"}
            p={isMobile ? "0" : "2"}
            position="relative"
            minWidth={isMobile ? "100%" : "300px"}
            maxWidth={isMobile ? "100%" : "300px"}
            cursor="pointer"
        >
            {/* <CardHeader bgColor={userStore.player?.faction}>
                <HStack justifyContent={"space-between"}>
                    <Heading ps="2" size="md">
                        {t(`projects.${name}.title`)}
                    </Heading>
                </HStack>
            </CardHeader> */}

            <Card.Body bgColor={project.completed ? "teal.800" : "orange.500"} position="relative" p={isMobile ? "1" : "2"}>
                {/* <Text>T{project.tier}</Text> */}
                {/* {project.id} */}
                {buildingInfo && (
                    <>
                        <Text>Unlock a new building</Text>
                        <HStack
                            alignItems={"start"}
                            border="1px solid"
                            borderColor="orange.600"
                            bgColor="teal.600"
                            color="orange.900"
                            boxShadow="md"
                        >
                            <Image src={`/images/buildings/${project.name.toLowerCase()}.png`} maxWidth={"60px"} />
                            <Stack gap="0">
                                <Text fontSize="md">{t(`buildings.${buildingInfo.name}.title`)}</Text>
                                <Box fontSize="0.7em">
                                    <ModList value={buildingInfo.baseEffects} />
                                </Box>
                                <Resources {...buildingInfo.cost} />
                            </Stack>
                        </HStack>
                    </>
                )}
                {project.type === "ui" && (
                    <Box bgColor="teal.600" color="orange.900" p="2">
                        <Tooltip content={t(`ui.${project.name}.description`)}>
                            <Text fontSize="md" as="span">
                                {t(`ui.${project.name}.title`)}
                            </Text>
                        </Tooltip>
                    </Box>
                )}
                {project.type === "gain" && (
                    <Center fontSize="2xl">
                        <Resources {...project.gain} cost={false}></Resources>
                    </Center>
                )}
                {project.baseEffects && <ModList value={project!.baseEffects} />}

                {!project.completed && (
                    <Progress value={project.workers} total={project.cost} height={["20px", "20px", "40px"]} progressColor="teal.800" />
                )}
                {project.completed && (
                    <Text textAlign={"center"} fontSize="xl" mt="2">
                        Completed
                    </Text>
                )}
                {!project.unlocked && (
                    <Text textAlign={"center"} fontSize="xl" mt="2">
                        Project is locked
                    </Text>
                )}
                {project.unlocked && !project.completed && (

                    <Stack
                        justifyContent={"center"}
                        bgColor={userStore.player?.faction}
                    >
                        <Box>
                            <Text>
                                Send <GameIcon name={ResourceType.WORKER} /> workers{" "}
                            </Text>
                        </Box>
                        <SimpleGrid columns={[2, 2, 2, 4]} gap={1}>
                            <Button
                                onClick={() => {
                                    workMutation.mutate(1)
                                }}
                                disabled={resources.workers < 1}
                            >
                                +1
                            </Button>
                            <Button
                                onClick={() => {
                                    workMutation.mutate(10)
                                }}
                                disabled={resources.workers < 10}
                            >
                                +10
                            </Button>
                            <Button
                                onClick={() => {
                                    workMutation.mutate(100)
                                }}
                                disabled={resources.workers < 100}
                            >
                                +100
                            </Button>
                            <Button
                                onClick={() => {
                                    workMutation.mutate(Math.floor(resources.workers))
                                }}
                                disabled={resources.workers < 1}
                            >
                                +{Math.floor(resources.workers)}
                            </Button>
                        </SimpleGrid>
                    </Stack>
                )}
            </Card.Body>
        </Card.Root>
    )
})

export default Project
