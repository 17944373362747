import { DialogBody, DialogCloseTrigger, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle } from "@/components/ui/dialog"
import {
    Box,
    Button,
    DialogBackdrop,
    HStack,
    Heading,
    IconButton,
    Text,
    Textarea,
} from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { FormEvent, FunctionComponent, useState } from "react"
import { MdEdit } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import { LeaderRole, updateMessage } from "../../api/faction"
import { useGameId, useIsUnlocked, useLeaderRole } from "../../hooks/query"
import { useStore } from "../../store"
import { UnlockType } from "../../types"
import { wrapCoordinates, wrapLinks } from "../../utils/chat"
import DateComponent from "../common/DateComponent"

interface LeaderMessageProps {
    leaderRole: LeaderRole
    message: string
    updatedAt: string
}
const LeaderMessage: FunctionComponent<LeaderMessageProps> = ({ leaderRole, message, updatedAt }) => {
    const { mapStore } = useStore()
    const [editMode, setEditMode] = useState(false)
    const gameId = useGameId()
    const queryClient = useQueryClient()
    const mapIsUnlocked = useIsUnlocked(UnlockType.UI_WORLD)
    const playerRole = useLeaderRole()
    const hasRole = leaderRole === playerRole

    const navigate = useNavigate()
    const mutation = useMutation({
        mutationFn: (message: string) => updateMessage(gameId, leaderRole, message),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["factions", gameId] })
            setEditMode(false)
        },
    })

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const formData = new FormData(e.currentTarget)
        mutation.mutate(formData.get("message") as string)
    }
    let wrappedContent = ""
    if (message) {
        const html = message.replaceAll("\n", "<br/>")
        wrappedContent = mapIsUnlocked ? wrapLinks(wrapCoordinates(html)) : html
    }

    const handleMessageClick = (e: any) => {
        if (e.target.dataset?.x && e.target.dataset?.y) {
            mapStore.setSelectedTile(parseInt(e.target.dataset!.x), parseInt(e.target.dataset!.y))
            navigate(`/play/${gameId}/world`)
        }
    }
    return (
        <Box mb="2">
            <HStack justify={"flex-start"} minHeight={30}>
                <Heading as="h3" size="sm">
                    {message ? `Message from ${leaderRole}` : `No message from ${leaderRole} yet`}
                </Heading>
                {hasRole && (
                    <IconButton
                        onClick={() => setEditMode(!editMode)}
                        aria-label="Edit"
                        size="sm"
                        variant="ghost"
                    >
                        <MdEdit />
                    </IconButton>
                )}
            </HStack>
            {message && (
                <>
                    <Text
                        className="message"
                        onClick={handleMessageClick}
                        fontSize="sm"
                        lineHeight={1.1}
                        dangerouslySetInnerHTML={{ __html: wrappedContent }}
                    ></Text>
                    <Text fontSize="xs" color="orange.300" float="right" pr="2">
                        Last updated <DateComponent date={updatedAt} />
                    </Text>
                </>
            )}

            {hasRole && (
                <DialogRoot lazyMount open={editMode} onOpenChange={(e:any) => setEditMode(e.open)}>
                    <DialogBackdrop />
                    <DialogContent>
                        <form action="post" onSubmit={(e) => handleSubmit(e)}>
                            <DialogCloseTrigger />
                            <DialogHeader>
                                <DialogTitle>Edit message</DialogTitle>
                            </DialogHeader>
                            <DialogBody>
                                <Textarea name="message" defaultValue={message} height="40" />
                            </DialogBody>
                            <DialogFooter>
                                <Button type="submit" colorPalette="green">
                                    Ok
                                </Button>
                                <Button colorPalette="red" onClick={() => setEditMode(false)}>
                                    Cancel
                                </Button>
                            </DialogFooter>
                        </form>
                    </DialogContent>
                </DialogRoot >
            )}
        </Box >
    )
}

export default LeaderMessage
