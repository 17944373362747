import { Box, HStack, Heading, Text } from "@chakra-ui/react"
import { useMutation } from "@tanstack/react-query"
import { observer } from "mobx-react-lite"
import { FunctionComponent } from "react"
import { fortify } from "../../../api/world"
import { useGameId, useIsUnlocked, useResources } from "../../../hooks/query"
import { UISpendResources } from "../../../hooks/utils"
import { useStore } from "../../../store"
import { ResourceType, UnlockType } from "../../../types"
import { Button } from "@/components/ui/button"
import GameIcon, { GameIconType } from "../../common/GameIcon"
import HelpText from "../../common/HelpText"
import Progress from "../../common/ProgressWithText"

const TileFortification: FunctionComponent = observer(() => {
    const { mapStore } = useStore()
    const selectedTile = mapStore.selectedTile
    const resources = useResources()

    const tileInfo = mapStore.getTileInfo(selectedTile!.x, selectedTile!.y)
    const isUnlocked = useIsUnlocked(UnlockType.UI_TILE_FORTIFICATION)
    const gameId = useGameId()

    // send workers
    const work = useMutation({
        mutationFn: (workers: number) => fortify(gameId, selectedTile!.x, selectedTile!.y, workers),
        onMutate: (workers) => {
            UISpendResources({ gameId, resources: { workers } })
        },
    })

    if (!tileInfo) return <Box></Box>
    return (
        <Box>
            <HStack alignItems={"center"} justifyContent={"space-between"}>
                <HStack>
                    <GameIcon name={GameIconType.FORTIFICATION} />
                    <Heading size="md" as="h3">
                        <HelpText tooltip="Fortification gives you a defense bonus (penalty to enemy attacks). Fortification are destroyed if an enemy faction capture this terrain.">
                            Fortification
                        </HelpText>
                    </Heading>
                </HStack>
                <Text fontSize="xs">lvl {tileInfo.fortificationDetails.level}/4</Text>
            </HStack>

            <Text>+{tileInfo.fortificationDetails.bonus}% fortification</Text>

            {tileInfo.fortificationDetails.level < 4 && tileInfo.fortificationDetails.allowed && isUnlocked && (
                <>
                    <Progress value={tileInfo.fortificationDetails.currentGoalWorkers} total={tileInfo.fortificationDetails.goal}>
                        {tileInfo.fortificationDetails.currentGoalWorkers} / {tileInfo.fortificationDetails.goal}
                    </Progress>
                    <HStack gap="0" justifyContent={"center"}>
                        <Button
                            onClick={() => {
                                work.mutate(1)
                            }}
                            disabled={resources.workers < 1}
                            margin="0.5"
                            p="2"
                        >
                            +1
                            <GameIcon name={ResourceType.WORKER} />
                        </Button>
                        <Button
                            onClick={() => {
                                work.mutate(10)
                            }}
                            disabled={resources.workers < 10}
                            margin="0.5"
                            p="2"
                        >
                            +10
                            <GameIcon name={ResourceType.WORKER} />
                        </Button>
                        <Button
                            onClick={() => {
                                work.mutate(100)
                            }}
                            disabled={resources.workers < 100}
                            margin="0.5"
                            p="2"
                        >
                            +100
                            <GameIcon name={ResourceType.WORKER} />
                        </Button>
                    </HStack>
                </>
            )}
        </Box>
    )
})

export default TileFortification
