import { action, computed, makeObservable, observable } from "mobx"
import BaseStore from "./BaseStore"
import RootStore from "./RootStore"
import { Color, parseColor } from "@chakra-ui/react"
import { Factions } from "@/types"
import { shadeColor } from "@/utils"

export enum BuildingOrders {
    CREATED = "CREATED",
    TYPE = "TYPE",
    WOOD_PRICE = "WOOD_PRICE",
    IRON_PRICE = "IRON_PRICE"
}

export default class SettingsStore extends BaseStore {

    showMapSoldiers = false
    tileOpacity = 20
    accessibilityMode = false
    hqViewCondensed = false
    hqViewOrder: BuildingOrders = BuildingOrders.CREATED
    battleLogQuantity = 1
    battleLogType: "all" | "military" | "workers" = "all"
    projectsLogQuantity = 1

    factionColors: Record<Factions, string> = {
        [Factions.RED]: "#FF0000",
        [Factions.BLUE]: "#0000FF",
        [Factions.YELLOW]: "#FFFF00",
        [Factions.GREEN]: "#00FF00",
        [Factions.NEUTRAL]: "#ffffff"
    }
    constructor(rootStore: RootStore) {
        super(rootStore)
        makeObservable(this, {
            showMapSoldiers: observable,
            tileOpacity: observable,
            accessibilityMode: observable,
            hqViewCondensed: observable,
            hqViewOrder: observable,
            battleLogQuantity: observable,
            battleLogType: observable,
            projectsLogQuantity: observable,
            load: action,
            setShowMapSoldiers: action,
            setTileOpacity: action,
            setAccessibilityMode: action,
            setHqViewCondensed: action,
            setHqViewOrder: action,
            setBattleLogQuantity: action,
            setBattleLogType: action,
            factionsColors: computed
        })

        this.load()
    }

    load = () => {
        const stored = localStorage.getItem('settings')
        if (stored) {
            const settings = JSON.parse(stored)
            if (settings.showMapSoldiers !== undefined)
                this.showMapSoldiers = settings.showMapSoldiers
            if (settings.tileOpacity !== undefined)
                this.tileOpacity = settings.tileOpacity
            if (settings.accessibilityMode !== undefined)
                this.accessibilityMode = settings.accessibilityMode
            if (settings.hqViewCondensed !== undefined)
                this.hqViewCondensed = settings.hqViewCondensed
            if (settings.hqViewOrder !== undefined)
                this.hqViewOrder = settings.hqViewOrder
            if (settings.battleLogQuantity !== undefined)
                this.battleLogQuantity = settings.battleLogQuantity
            if (settings.battleLogType !== undefined)
                this.battleLogType = settings.battleLogType
            if (settings.projectsLogQuantity !== undefined)
                this.projectsLogQuantity = settings.projectsLogQuantity
            if (settings.factionColors !== undefined) {
                this.factionColors = settings.factionColors
            }
        }


    }
    saveToLocalStorage = () => {
        const settings = {
            showMapSoldiers: this.showMapSoldiers,
            tileOpacity: this.tileOpacity,
            accessibilityMode: this.accessibilityMode,
            hqViewCondensed: this.hqViewCondensed,
            hqViewOrder: this.hqViewOrder,
            battleLogQuantity: this.battleLogQuantity,
            battleLogType: this.battleLogType,
            projectsLogQuantity: this.projectsLogQuantity,
            factionColors: this.factionColors
        }
        localStorage.setItem('settings', JSON.stringify(settings))
    }

    setShowMapSoldiers = (value: boolean) => {
        this.showMapSoldiers = value
        this.saveToLocalStorage()
    }

    setTileOpacity = (value: number) => {
        this.tileOpacity = value
        this.saveToLocalStorage()
    }

    setAccessibilityMode = (value: boolean) => {
        this.accessibilityMode = value
        this.saveToLocalStorage()
    }

    setHqViewCondensed = (value: boolean) => {
        this.hqViewCondensed = value
        this.saveToLocalStorage()
    }

    setHqViewOrder = (value: BuildingOrders) => {
        this.hqViewOrder = value
        this.saveToLocalStorage()
    }

    setBattleLogQuantity = (value: number) => {
        this.battleLogQuantity = value
        this.saveToLocalStorage()
    }

    setBattleLogType = (value: "all" | "military" | "workers") => {
        this.battleLogType = value
        this.saveToLocalStorage()
    }

    setProjectsLogQuantity = (value: number) => {
        this.projectsLogQuantity = value
        this.saveToLocalStorage()
    }

    setFactionColor = (faction: Factions, color: string) => {
        this.factionColors[faction] = color
        this.saveToLocalStorage()
    }

    getFactionColor = (faction?: Factions) => {
        if (!faction) return parseColor("orange.300")
        return parseColor(this.factionColors[faction])
    }
    getFactionColors = (faction?: Factions | null) => {
        return this.factionsColors[faction || Factions.NEUTRAL]
    }

    getFactionColorAsNumber = (faction?: Factions) => {
        const colors = this.getFactionColors(faction)
        return {
            bg: parseColor(colors.bg).toHexInt(),
            username: parseColor(colors.username).toHexInt(),
            color: parseColor(colors.color).toHexInt(),
        }
    }

    get factionsColors() {
        // compute colors for each faction and return a dict with faction as key and object of different colors as value
        const colors = Object.entries(this.factionColors).reduce((acc, [faction, baseColor]) => {
            acc[faction as Factions] = {
                bg: shadeColor(baseColor, 0.3),
                username: shadeColor(baseColor, 0.3),
                color: baseColor,
            }
            return acc
        }, {} as Record<Factions, { bg: string; username: string; color: string }>)
        return colors
    }


}
