import {
    createSystem,
    defaultConfig,
    defineConfig,
    defineLayerStyles,
    defineRecipe,
    defineTextStyles,
} from "@chakra-ui/react"
import { Factions } from "../types"
import { buttonRecipe } from "./button"
import { panelRecipe } from "./panel"
import { inputRecipe } from "./input"
import { tabsSlotRecipe } from "./tabs"
import { menuRecipe } from "./menu"
import { cardRecipe } from "./card"
import { dialogRecipe } from "./dialog"
import { tooltipSlotRecipe } from "./tooltip"
import { popoverSlotRecipe } from "./popover"
import { switchRecipe } from "./switch"
import { listSlotRecipe } from "./list"
import { linkRecipe } from "./link"

const textStyles = defineTextStyles({
    discreet: {
        description: "Discreet text",
        value: {
            fontSize: "0.8em",
        }
    }
})

const layerStyles = defineLayerStyles({
    info: {
        description: "Info block",
        value: {
            bg: "orange.100",
            border: "1px solid",
            borderColor: "orange.500",
            color: "black",
            boxShadow: "md",
            padding: 2
        },
    },
    error: {
        description: "Error block",
        value: {
            bg: "red.800",
            border: "1px solid",
            borderColor: "red.500",
            color: "white",
            boxShadow: "md",
            padding: 2
        },
    },
    warning: {
        description: "Warning block",
        value: {
            bg: "red.800",
            border: "1px solid",
            borderColor: "red.500",
            color: "white",
            boxShadow: "md",
            padding: 2
        },
    },
})

const checkboxRecipe = defineRecipe({
    defaultVariants: {
        colorPalette: "orange",
    }
})


const config = defineConfig({
    // disableLayers: true,
    globalCss: {
        "html": {
            fontSize: "85%",
        },
        "body": {
            cursor: "url('/ui/cursor.png'), auto",
            color: "orange.100",
            backgroundColor: "orange.800",

        },
        "a": {
            _hover: {
                textDecoration: "underline",
            }
        },
    },

    theme: {
        tokens: {
            zIndex: {
                popover: { value: 1000 },
            },
            fonts: {
                heading: { value: `'Retro gaming', sans-serif` },
                body: { value: `'Retro gaming', sans-serif` },
            },
            colors: {
                [Factions.RED]: { value: "red.800" },
                [Factions.GREEN]: { value: "green.800" },
                [Factions.BLUE]: { value: "blue.800" },
                [Factions.YELLOW]: { value: "yellow.800" },
                orange: {
                    50: { value: "#F7F3ED" },
                    100: { value: "#E9DDCE" },
                    200: { value: "#DBC7AE" },
                    300: { value: "#CDB18E" },
                    400: { value: "#BF9B6E" },
                    500: { value: "#B0854F" },
                    600: { value: "#8D6A3F" },
                    700: { value: "#6A502F" },
                    800: { value: "#47351F" },
                    900: { value: "#231B10" },
                    950: { value: "#110D08" },
                },
                yellow: {
                    50: { value: "#FFF8E6" },
                    100: { value: "#FEEDB8" },
                    200: { value: "#FEE18B" },
                    300: { value: "#FED55D" },
                    400: { value: "#FDC930" },
                    500: { value: "#FDBD02" },
                    600: { value: "#CA9702" },
                    700: { value: "#987101" },
                    800: { value: "#654C01" },
                    900: { value: "#332600" },
                    950: { value: "#161300" },
                },
                teal: {
                    50: { value: "#EFF5F5" },
                    100: { value: "#D2E3E4" },
                    200: { value: "#B6D1D3" },
                    300: { value: "#99C0C2" },
                    400: { value: "#7CAEB1" },
                    500: { value: "#5F9CA0" },
                    600: { value: "#4C7D80" },
                    700: { value: "#395E60" },
                    800: { value: "#263E40" },
                    900: { value: "#131F20" },
                    950: { value: "#090F10" },
                },
                red: {
                    50: { value: "#FDE8E8" },
                    100: { value: "#F9BEBE" },
                    200: { value: "#F59494" },
                    300: { value: "#F16A6A" },
                    400: { value: "#ED4040" },
                    500: { value: "#E91616" },
                    600: { value: "#BA1212" },
                    700: { value: "#8C0D0D" },
                    800: { value: "#5D0909" },
                    900: { value: "#2F0404" },
                    950: { value: "#160202" },
                },
                green: {
                    50: { value: "#EFF6EF" },
                    100: { value: "#D3E5D2" },
                    200: { value: "#B8D3B5" },
                    300: { value: "#9CC298" },
                    400: { value: "#80B17B" },
                    500: { value: "#64A05F" },
                    600: { value: "#50804C" },
                    700: { value: "#3C6039" },
                    800: { value: "#284026" },
                    900: { value: "#142013" },
                    950: { value: "#091009" },
                },
                light: {
                    50: { value: "#FFFFFF" },
                    100: { value: "#F0F0F0" },
                    200: { value: "#D0D0D0" },
                    300: { value: "#B0B0B0" },
                    400: { value: "#909090" },
                    500: { value: "#707070" },
                    600: { value: "#505050" },
                    700: { value: "#303030" },
                    800: { value: "#101010" },
                    900: { value: "#000000" },
                }
            },
        },
        semanticTokens: {
            colors: {
                // bg: { emphasized: { value: "{colors.orange.500}" } },
                border: { value: "{colors.orange.500}" },
                dark: { value: "{colors.orange.900}" },
                positive: { value: "{colors.green.700}" },
                negative: { value: "{colors.red.700}" },
                gray: {
                    solid: { value: "{colors.gray.900}" },
                    contrast: { value: "{colors.gray.100}" },
                    fg: { value: "{colors.gray.100}" },
                    muted: { value: "{colors.gray.200}" },
                    subtle: { value: "{colors.gray.300}" },
                    emphasized: { value: "{colors.gray.500}" },
                    focusRing: { value: "{colors.gray.500}" },
                },
                orange: {
                    solid: { value: "{colors.orange.700}" },
                    contrast: { value: "{colors.orange.100}" },
                    fg: { value: "{colors.orange.100}" },
                    muted: { value: "{colors.orange.200}" },
                    subtle: { value: "{colors.orange.300}" },
                    emphasized: { value: "{colors.orange.500}" },
                    focusRing: { value: "{colors.orange.500}" },
                },
                red: {
                    solid: { value: "{colors.red.700}" },
                    contrast: { value: "{colors.red.100}" },
                    fg: { value: "{colors.red.100}" },
                    muted: { value: "{colors.red.200}" },
                    subtle: { value: "{colors.red.300}" },
                    emphasized: { value: "{colors.red.600}" },
                    focusRing: { value: "{colors.red.500}" },
                },
                blue: {
                    solid: { value: "{colors.blue.700}" },
                    contrast: { value: "{colors.blue.100}" },
                    fg: { value: "{colors.blue.100}" },
                    muted: { value: "{colors.blue.200}" },
                    subtle: { value: "{colors.blue.300}" },
                    emphasized: { value: "{colors.blue.600}" },
                    focusRing: { value: "{colors.blue.500}" },
                },
                green: {
                    solid: { value: "{colors.green.700}" },
                    contrast: { value: "{colors.green.100}" },
                    fg: { value: "{colors.green.100}" },
                    muted: { value: "{colors.green.200}" },
                    subtle: { value: "{colors.green.300}" },
                    emphasized: { value: "{colors.green.600}" },
                    focusRing: { value: "{colors.green.500}" },
                },
                light: {
                    solid: { value: "{colors.light.50}" },
                    contrast: { value: "{colors.light.800}" },
                    fg: { value: "{colors.light.50}" },
                    muted: { value: "{colors.light.200}" },
                    subtle: { value: "{colors.light.300}" },
                    emphasized: { value: "{colors.light.200}" },
                    focusRing: { value: "{colors.light.500}" },
                }
            },
        },


        layerStyles: layerStyles,
        textStyles: textStyles,

        recipes: {
            button: buttonRecipe,
            panel: panelRecipe,
            input: inputRecipe,
            checkbox: checkboxRecipe,
            link: linkRecipe,
        },
        slotRecipes: {
            tabs: tabsSlotRecipe,
            menu: menuRecipe,
            card: cardRecipe,
            dialog: dialogRecipe,
            popover: popoverSlotRecipe,
            tooltip: tooltipSlotRecipe,
            switch: switchRecipe,
            list: listSlotRecipe,
        }

    }
})





export default createSystem(defaultConfig, config)
