import { useQuery, useQueryClient } from "@tanstack/react-query"
import { Resources, gatherResources, getConfig, getEffects, getHQInfo } from "../api/game"
import { getConnectedPlayers, getPlayer } from "../api/player"
import { getInvasionsInfo } from "../api/world"
import { PlayerResources, ResourceType, UnlockType } from "../types"
import { rootStore } from "../store"
import { getProjects } from "../api/projects"
import { queryClient } from "../query_client"
import { useParams } from "react-router-dom"
import { getGame } from "../api/games"
import { matchPath } from "react-router-dom"
import { FactionData, LeaderRole, getFactions } from "../api/faction"

export const useGameId = () => {
    const params = useParams()
    const gameId = parseInt(params.gameId as string, 10)
    if (isNaN(gameId)) {
        return -1
    }
    return gameId
}

export const useInGame = () => {
    /** return true if we're in-game */
    const res = matchPath({ path: "/play/:gameId" }, window.location.pathname)
    return !!res?.params.gameId
}

export const useGatherResources = () => {
    const gameId = useGameId()
    // gather and get player resources
    const resources = useQuery({
        queryKey: ["gatherResources", gameId],
        queryFn: () => gatherResources(gameId),
        refetchOnWindowFocus: true,
        staleTime: 20 * 1000,
        gcTime: 20 * 1000,
        refetchInterval: 20 * 1000,
        retry: 0,
    })
    return resources
}

export const usePlayer = () => {
    // get player info
    const gameId = useGameId()

    const player = useQuery({
        queryKey: ["player", gameId],
        queryFn: () => getPlayer(gameId),
    })
    if (player.data?.userId && rootStore.userStore.player?.userId !== player.data.userId) {
        setTimeout(() => {
            rootStore.userStore.setPlayer(player.data)
        }, 1)
    }
    return player.data
}

export const useResources = (): PlayerResources => {
    // get player resources
    // get player resources
    const { data } = useGatherResources()

    const wood = data?.wood || 0
    const iron = data?.iron || 0
    const soldier = data?.soldiers || 0
    const worker = data?.workers || 0
    const guardian = data?.guardian || 0
    const knight = data?.knight || 0
    const ticks = data?.ticks || 0
    return {
        [ResourceType.WOOD]: wood,
        [ResourceType.IRON]: iron,
        [ResourceType.SOLDIER]: soldier,
        [ResourceType.WORKER]: worker,
        [ResourceType.GUARDIAN]: guardian,
        [ResourceType.KNIGHT]: knight,
        [ResourceType.TICKS]: ticks,
    }
}

export const useHQInfo = () => {
    // get player hq info
    const gameId = useGameId()
    return useQuery({ queryKey: ["hqInfo", gameId], queryFn: () => getHQInfo(gameId), enabled: gameId !== -1 })
}

export const useGame = () => {
    // get player game info
    const gameId = useGameId()
    return useQuery({ queryKey: ["game", gameId], queryFn: () => getGame(gameId), refetchInterval: 60 * 1000})
}

export const useEffects = () => {
    // get all effects
    const gameId = useGameId()
    return useQuery({ queryKey: ["effects", gameId], queryFn: () => getEffects(gameId), refetchInterval: 60 * 1000 })
}

export const useConfig = () => {
    const gameId = useGameId()
    const config = useQuery({
        queryKey: ["config", gameId],
        queryFn: () => getConfig(gameId),
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        gcTime: Infinity,
        refetchInterval: 0,
    })
    return config.data
}

export const useIsUnlocked = (name: UnlockType) => {
    /**  check if player has unlocked something */
    // we're testing this because of chat message that can use this hook outside of game
    const hqInfo = useHQInfo()
    if (!hqInfo.data) return false
    return hqInfo.data.unlocks.includes(name)
}

// export const useTileInfo = (x: number | undefined, y: number | undefined, enabled: boolean = true) => {
//     // get tile info
//     const tile = useQuery({
//         queryKey: ["tileInfo", x, y],
//         queryFn: () => getTileInfo(x!, y!),
//         enabled: !!(x !== undefined && y !== undefined) && enabled
//     })
//     return tile
// }

export const useInvasionInfo = () => {
    // get invasion info
    const gameId = useGameId()
    const world = useQuery({
        queryKey: ["worldInfo", gameId],
        queryFn: () => getInvasionsInfo(gameId),
        // it's supposed to be handled by sockets
        refetchOnWindowFocus: true,
        refetchInterval: 60000,
        staleTime: 10000,
    })
    if (world.data) {
        setTimeout(() => {
            if(rootStore.mapStore.tileInfosGenerated !== world.data.generated) {
                rootStore.mapStore.setTileInfos(world.data.world)
                rootStore.mapStore.setTileInfosGenerated(world.data.generated)
            }
        }, 1)
    }
    return world
}

export const useConnectedPlayers = () => {
    const gameId = useGameId()
    return useQuery({
        queryKey: ["connected", gameId],
        queryFn: () => getConnectedPlayers(gameId),
        refetchOnWindowFocus: false,
    })
}

export const useProjects = () => {
    // get projects
    const gameId = useGameId()
    return useQuery({
        queryKey: ["projects", gameId],
        queryFn: () => getProjects(gameId),
        staleTime: 60 * 1000,
    })
}

export const useFactions = () => {
    const gameId = useGameId()
    return useQuery({
        queryKey: ["factions", gameId],
        queryFn: () => getFactions(gameId),
        enabled: gameId !== -1,
    })
}

export const useFaction = () => {
    const factions = useFactions()
    if(!factions.data) return
    return factions.data.find((f) => f.my_faction)
}

export const useLeaderRole = () => {
    const faction = useFaction()
    const player = usePlayer()
    if (faction?.leader === player?.userId) return LeaderRole.Leader
    if (faction?.generals.includes(player!.userId)) return LeaderRole.General
    if (faction?.diplomats.includes(player!.userId)) return LeaderRole.Diplomat
}
