import type { ButtonProps as ChakraButtonProps } from "@chakra-ui/react"
import {
  AbsoluteCenter,
  Button as ChakraButton,
  Spinner,
  Span,
  Box,
} from "@chakra-ui/react"
import * as React from "react"
import { useIsMobile } from "../../hooks/utils"
import Wrap from "../common/Wrap"
import { Tooltip } from "./tooltip"
import { DialogContent, DialogRoot, DialogBody, DialogFooter, DialogHeader, DialogTitle, DialogCloseTrigger } from "./dialog"

interface ButtonLoadingProps {
  loading?: boolean
  loadingText?: React.ReactNode
  confirm?: boolean
  tooltip?: string
  pressed?: boolean
}

const ConfirmDialog = ({ open, setOpen, onClick }: { open: boolean; setOpen: (open: boolean) => void; onClick: (e: any) => void }) => (
  <DialogRoot lazyMount open={open} onOpenChange={(e: any) => setOpen(e.open)}>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Confirm</DialogTitle>
        <DialogCloseTrigger />
      </DialogHeader>
      <DialogBody>
        Are you sure ?
      </DialogBody>
      <DialogFooter>
        <Button as="button" colorPalette="gray" mr={3} onClick={() => setOpen(false)}>
          No
        </Button>
        <Button colorPalette="red" onClick={(e) => { onClick(e); setOpen(false) }}>
          Yes
        </Button>
      </DialogFooter>
    </DialogContent>
  </DialogRoot>
)

export interface ButtonProps extends ChakraButtonProps, ButtonLoadingProps { }

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(props, ref) {
    const { loading, disabled, loadingText, confirm, tooltip, pressed, onClick, children, ...rest } = props
    // for confirm dialog
    const [open, setOpen] = React.useState(false)
    const isMobile = useIsMobile()

    const click = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        if (confirm) {
          setOpen(true)
        } else onClick(e)
      }
    }, [onClick, confirm, setOpen])

    return (
      <>
        <Wrap if={!!tooltip && !isMobile} with={(children) => <Tooltip content={<Box>{tooltip}</Box>}>{children}</Tooltip>}>
          <ChakraButton disabled={loading || disabled} ref={ref} onClick={click}
            {...(pressed && {
              borderStyle: "inset",
              borderRadius: "none",
              _before: {
                border: "2px solid",
                borderColor: "colorPalette.600",
                borderStyle: "outset",
                borderRadius: "none",
              },
            })}
            className="group"
            {...rest}
          >
            <Span _groupHover={{ transform: (!disabled && !pressed) ? "translate3d(1px,1px,0)" : "" }}>
              {loading && !loadingText ? (
                <>
                  <AbsoluteCenter display="inline-flex">
                    <Spinner size="inherit" color="inherit" />
                  </AbsoluteCenter>
                  <Span opacity={0}>{children}</Span>
                </>
              ) : loading && loadingText ? (
                <>
                  <Spinner size="inherit" color="inherit" />
                  {loadingText}
                </>
              ) : (
                <>
                  {children}
                </>
              )}
            </Span>
          </ChakraButton>
        </Wrap>
        {confirm && <ConfirmDialog open={open} setOpen={setOpen} onClick={onClick!} />}
      </>
    )
  },
)

