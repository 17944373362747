import { makeObservable, computed, observable, action } from "mobx"
import BaseStore from "./BaseStore"
import RootStore from "./RootStore"
import { Player } from "../api/player"
import Cookies from 'js-cookie'
import _ from "lodash"
import * as Sentry from "@sentry/react";

// import { navigate } from "../utils"

const COOKIE_DOMAIN = import.meta.env.VITE_COOKIE_DOMAIN

export default class UserStore extends BaseStore {

    apiToken: string | null = null
    player: Player | null = null
    gameId: number | null = null

    constructor(rootStore: RootStore) {
        super(rootStore)
        makeObservable(this, {
            apiToken: observable,
            player: observable,
            gameId: observable,
            logout: action,
            setApiToken: action,
            setPlayer: action,
            setGameId: action,
            isAuthenticated: computed
        })
        this.apiToken = window.localStorage.getItem('apiToken') || null
        if (!this.apiToken && Cookies.get('api_token')) {
            this.setApiToken(Cookies.get('api_token')!)
        }
    }

    logout() {
        window.localStorage.removeItem('apiToken')
        Cookies.remove('api_token', { path: "/", domain: COOKIE_DOMAIN })
        this.apiToken = null
        window.location.href = '/login'
        Sentry.setUser(null);
    }

    setApiToken(token: string) {
        this.apiToken = token
        // Cookies.set('api_token', token)
        window.localStorage.setItem('apiToken', token)
        Cookies.remove('api_token', { path: "/", domain: COOKIE_DOMAIN })
    }

    setPlayer(player: Player) {
        if (_.isEqual(this.player, player)) return
        this.player = player
        console.log("player set", player)
        this.rootStore.socketStore.startNotificationsSocket()
        Sentry.setUser({ username: player.username, id: player.userId.toString() });
    }

    setGameId(gameId: number) {
        this.gameId = gameId
    }


    get isAuthenticated() {
        return this.apiToken ? true : false
    }

}
