import { Box, Tabs } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent, useRef, useEffect, useState } from "react"
import { useStore } from "../../store"
import Channel from "./Channel"
import { useIsVisible } from "../../hooks/utils"

interface ChatProps {
    channels: { id: string; name: string }[] /** channels to display */
}
const Chat: FunctionComponent<ChatProps> = observer(({ channels }) => {
    const { chatStore, gameStore } = useStore()
    const ref = useRef<HTMLDivElement>(null)


    // if windows is focused, and game was blured since more than 60 seconds (time to refresh the invasions info), send reload the chat sockets to avoid out of sync messages
    useEffect(() => {
        if (gameStore.lastLostFocusDate && gameStore.windowsFocus && new Date().getTime() - gameStore.lastLostFocusDate.getTime() > 60000) {
            chatStore.reconnectToAllChannels()
        }
    }, [gameStore.lastLostFocusDate, gameStore.windowsFocus])

    const handleTabsChange = (selected: any) => {
        chatStore.setActiveChannel(selected.value)
    }

    const isVisible = useIsVisible(ref)

    useEffect(() => {
        chatStore.setIsChatVisible(isVisible)
    }, [isVisible])

    useEffect(() => {
        const hasChannelsInStore = Object.keys(chatStore.channels).length > 0
        const firstChannel = channels[0]
        const isActiveChannelValid = channels.some(channel => channel.id === chatStore.activeChannelId)
        if (hasChannelsInStore && firstChannel && (!chatStore.activeChannelId || !isActiveChannelValid)) {
            chatStore.setActiveChannel(firstChannel.id)
        }
    }, [Object.keys(chatStore.channels).length, channels, chatStore.activeChannelId])

    useEffect(() => {
        channels.forEach((channel) => {
            chatStore.initChannel(channel.id, channel.name)
        })
    }, [])

    return (
        <Box className="chat" ref={ref}>
            <Tabs.Root onValueChange={handleTabsChange} variant="chat" padding="1" value={chatStore.activeChannelId}>
                <Tabs.List overflowX="auto" overflowY="hidden">
                    {channels.map((channelD) => {
                        const channel = chatStore.getChannel(channelD.id)
                        if (!channel) return null
                        return (
                            <Tabs.Trigger padding="1" paddingX="2" value={channel.id} key={channel.id}>
                                {channel.name}{" "}
                                {channel.hasUnreadMessages && (
                                    <Box
                                        display={"inline-block"}
                                        borderRadius="50%"
                                        width="8px"
                                        height="8px"
                                        bgColor="red.800"
                                        position="relative"
                                        top="-6px"
                                        left="0"
                                    ></Box>
                                )}
                            </Tabs.Trigger>
                        )
                    })}
                </Tabs.List>
                {channels.map((channelD) => {
                    const channel = chatStore.getChannel(channelD.id)
                    if (!channel) return null
                    return (
                        <Tabs.Content key={channel.id} value={channel.id}>
                            <Channel channelId={channel.id}></Channel>
                        </Tabs.Content>
                    )
                })}
            </Tabs.Root>
        </Box >
    )
})

export default Chat
