export const getKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>
export const getValues = Object.values as <T extends object>(obj: T) => Array<T[keyof T]>
export const getEntries = Object.entries as <T extends object>(obj: T) => Array<[keyof T, T[keyof T]]>

export const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;
export const clamp = (a: number, min = 0, max = 1) => Math.min(max, Math.max(min, a));
export const invlerp = (x: number, y: number, a: number) => clamp((a - x) / (y - x));
export const range = (x1: number, y1: number, x2: number, y2: number, a: number) => lerp(x2, y2, invlerp(x1, y1, a));

export const toShortNumber = (num: number): string => {
    if (num < 1000) return num.toString()
    if (num < 10000) return (num / 1000).toFixed(1) + 'k'
    if (num < 100000) return (num / 1000).toFixed(0) + 'k'
    return (num / 1000000).toFixed(0) + 'm'
}

Date.prototype.addHours = function (h: number) {
    this.setTime(this.getTime() + (h * 60 * 60 * 1000));
    return this;
}
export const shadeColor = function(color: string, decimal: number): string {
    const base = color.startsWith('#') ? 1 : 0;

    let r = parseInt(color.substring(base, base + 2), 16);
    let g = parseInt(color.substring(base + 2, base + 4), 16);
    let b = parseInt(color.substring(base + 4, base + 6), 16);

    r = Math.round(r * decimal);
    g = Math.round(g * decimal);
    b = Math.round(b * decimal);

    r = clamp(r, 0, 255);
    g = clamp(g, 0, 255);
    b = clamp(b, 0, 255);

    const rr = r.toString(16).padStart(2, '0');
    const gg = g.toString(16).padStart(2, '0');
    const bb = b.toString(16).padStart(2, '0');

    return `#${rr}${gg}${bb}`;
}