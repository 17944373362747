import { Text } from "@chakra-ui/react"
import { FunctionComponent, useEffect, useState, useRef } from "react"
import { useConfig, useGatherResources } from "../hooks/query"
import CurrentResources from "./resources/CurrentResources"
import Progress from "./common/ProgressWithText"

const interval: number = 0

function useInterval(callback: () => void, delay: number | null) {
    const savedCallback = useRef<() => void>()

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current!()
        }
        if (delay !== null) {
            const id = setInterval(tick, delay)
            return () => clearInterval(id)
        }
    }, [delay])
}

const GatherProgress: FunctionComponent = () => {
    const [progress, setProgress] = useState(0)
    const [startDate, setStartDate] = useState(new Date().getTime())
    const [count, setCount] = useState(0)
    const { status, data, error, isFetching, refetch } = useGatherResources()
    const config = useConfig()
    const tick_duration = (config?.modeConfig.tick_duration || 20) * 1000

    useInterval(() => {
        const currentDate = new Date().getTime()

        const progress = ((currentDate - (startDate + count * tick_duration)) / tick_duration) * 100
        setProgress(progress)
        if (progress >= 105) {
            setCount((count) => count + 1)
            refetch()
            setProgress(0)
        }
        if (progress >= 120) {
            // something bad happens, we're out of sync, restart
            setStartDate(new Date().getTime())
            setCount(0)
        }
    }, 500)

    useEffect(() => {
        refetch()
        return () => {
            clearInterval(interval!)
        }
    }, [])

    return (
        <>
            <progress value={progress} max={100} className="gather-progress" style={{ width: "100%" }}></progress>
            {/* <Progress
                value={progress}
                bgColor="orange.200"
                width={"full"}
                title="Gathering resources..."
                css={{
                    "& > div:first-of-type": {
                        transitionProperty: "width",
                        transitionDuration: progress === 0 ? "0s" : "500ms",
                        transitionTimingFunction: "linear",
                        backgroundColor: "#10276b",
                    },
                }}
            ></Progress> */}
        </>
    )
}

export default GatherProgress
