
// import { switchAnatomy } from '@chakra-ui/anatomy'

import { defineRecipe, defineSlotRecipe } from "@chakra-ui/react";

// const { definePartsStyle, defineMultiStyleConfig } =
//     createMultiStyleConfigHelpers(switchAnatomy.keys)

// const baseStyle = definePartsStyle({
//     // define the part you're going to style
//     container: {
//         borderRadius: "none",
//     },
//     thumb: {
//         bg: 'orange.500',
//         _checked: {
//             bg: 'black',
//         },
//     },
//     track: {
//         borderRadius: "none",
//         bg: 'orange.200'
//     },
// })

// export const Switch = defineMultiStyleConfig({ baseStyle })
// import { inputAnatomy } from '@chakra-ui/anatomy'
// const { definePartsStyle, defineMultiStyleConfig } =
//     createMultiStyleConfigHelpers(inputAnatomy.keys)

// const baseStyle = definePartsStyle({
//     variants: {
//         outline: {
//             // define the part you're going to style
//             field: {
//                 borderColor: "gray.200"
//             }
//         }
//     }
// })

export const switchRecipe = defineSlotRecipe({
    slots: ["root", "label", "indicator", "control", "thumb"],
    base: {
        root: {
            borderRadius: "none",
        },

        label: {
        },

        indicator: {
            borderRadius: "none",
        },

        control: {
            borderRadius: "none",
        },

        thumb: {
            borderRadius: "none"
        },
    },
    variants: {
        variant: {
            solid: {
                root: {
                    borderRadius: "none",
                },

                label: {
                },

                indicator: {
                    borderRadius: "none",
                },

                control: {
                    borderRadius: "none",
                    bg: 'orange.200'
                },

                thumb: {
                    bg: 'orange.500',
                    _checked: {
                        bg: 'black',
                    },
                },
            }
        }
    }

})
