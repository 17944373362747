import { defineSlotRecipe } from "@chakra-ui/react";

export const popoverSlotRecipe = defineSlotRecipe({
  className: "chakra-popover",
  slots: ['content', 'header', 'body', 'footer', 'arrow', 'arrowTip'],
  base: {
    content: {
      bg: 'orange.800', // change the background of the content
      border: 'none',
      borderRadius: 'sm',
      boxShadow: 'md',
    },
  }
})


