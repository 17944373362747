import {
    Box,
    chakra,
    Collapsible,
    HStack,
    Image,
    Stack,
    Text,
    useRadioGroup,
} from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { FunctionComponent, useState } from "react"
import { build } from "../../api/game"
import { useConfig, useGameId, useHQInfo } from "../../hooks/query"
import { useHasEnoughResources, UISpendResources } from "../../hooks/utils"
import { BuildingType } from "../../types"
import ModList from "../resources/ModList"
import Resources from "../resources/Resources"
import { TbHammer } from "react-icons/tb"
import { useTranslation } from "react-i18next"
import { Button } from "../ui/button"
import {
    DialogBody,
    DialogCloseTrigger,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogRoot,
    DialogTitle,
    DialogTrigger,
    DialogBackdrop
} from "@/components/ui/dialog"

type BuildModalProps = {
    inactive?: boolean
}
const BuildModal: FunctionComponent<BuildModalProps> = ({ inactive = false }) => {
    const queryClient = useQueryClient()
    const gameId = useGameId()
    const { data: hqInfo } = useHQInfo()
    const { t } = useTranslation()
    const unlocks = hqInfo?.unlocks || []
    const [isOpen, setOpen] = useState(false)
    const [value, setValue] = useState<BuildingType | null>(null)

    const config = useConfig()

    const onCloseModal = () => {
        setOpen(false)
        setValue(null)
    }

    const mutation = useMutation({
        mutationFn: () => build(gameId, value as BuildingType),
        onMutate: () => {
            const buildingInfo = config?.buildings.find((building) => building.name == value)
            if (!buildingInfo) return
            UISpendResources({
                gameId,
                resources: buildingInfo.cost,
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["hqInfo", gameId] })
            queryClient.invalidateQueries({ queryKey: ["effects", gameId] })
            queryClient.invalidateQueries({ queryKey: ["unlocks", gameId] })
            onCloseModal()
        },
    })

    const existingBuildings = hqInfo?.buildings.map((building) => building.name)

    const buildings =
        config?.buildings.filter((building) => {
            if (building.requires && !unlocks.includes(building.requires)) return false
            if (building.unique && existingBuildings?.includes(building.name)) return false
            return true
        }) || []
    // sort by hq level
    buildings.sort((a, b) => a.hq - b.hq)

    const buildingInfo = config?.buildings.find((building) => building.name == value)
    const enoughResources = useHasEnoughResources(buildingInfo?.cost || {})
    const enoughHQ = (buildingInfo?.hq || 0) <= (hqInfo?.hq.level || 0)

    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                title={"Build"}
                colorPalette={inactive ? "blue" : "green"}
                size={inactive ? "sm" : "lg"}
            >
                <TbHammer />{inactive ? "Show buildings" : "Build"}
            </Button>

            <DialogRoot scrollBehavior="inside" size="xl" open={isOpen} onOpenChange={(e: any) => setOpen(e.open)}>
                <DialogBackdrop />
                <DialogContent>
                    <DialogCloseTrigger />
                    <DialogHeader>
                        <DialogTitle>New building</DialogTitle>
                    </DialogHeader>
                    <DialogBody>
                        <Box>
                            <Stack gap={0}>
                                {buildings.map((building) => (
                                    <BuildBuilding
                                        name={building.name}
                                        key={building.name}
                                        selected={value === building.name}
                                        setValue={setValue}
                                    />
                                ))}
                            </Stack>
                        </Box>
                    </DialogBody>
                    <DialogFooter>
                        {!inactive && (
                            <Button
                                mr={3}
                                onClick={() => mutation.mutate()}
                                loading={mutation.isPending}
                                disabled={!value || !enoughResources || !enoughHQ || mutation.isPending}
                            >
                                Build
                            </Button>
                        )}
                        <Button onClick={onCloseModal} colorPalette="red">
                            Close
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot>



        </>
    )
}

interface BuildingProps {
    name: BuildingType
    selected: boolean
    setValue: (value: BuildingType | null) => void
}
const BuildBuilding: FunctionComponent<BuildingProps> = ({ name, setValue, selected }) => {
    const { data: hqInfo } = useHQInfo()
    const config = useConfig()
    const buildingInfo = config?.buildings.find((building) => building.name == name)
    const { t } = useTranslation()
    if (!buildingInfo) return <Text>Invalid building</Text>
    const buildable = buildingInfo.hq <= (hqInfo?.hq.level || 0)

    return (
        <Box
            bg={selected ? "orange.700" : "transparent"}
            p={4}
            borderBottom="1px solid"
            borderColor="orange.800"
            cursor="pointer"
            onClick={() => setValue(name)}
        >
            <HStack justifyContent={"space-between"}>
                <Stack gap="0">
                    <Text fontSize="2xl">{t(`buildings.${buildingInfo.name}.title`)}</Text>
                    {!buildable && <Text color="negative">Requires a level {buildingInfo.hq} village</Text>}
                </Stack>

                <Resources {...buildingInfo.cost} />
            </HStack>

            {buildable && (
                <Collapsible.Root open={selected}>
                    <Collapsible.Content>
                        <SelectedBuilding name={buildingInfo.name} />
                    </Collapsible.Content>
                </Collapsible.Root>
            )}
        </Box>
    )
}

interface SelectedBuildingProps {
    name: BuildingType
}
const SelectedBuilding: FunctionComponent<SelectedBuildingProps> = ({ name }) => {
    const config = useConfig()
    const buildingInfo = config?.buildings.find((building) => building.name == name)

    const effects = buildingInfo?.baseEffects
    return (
        <Stack>
            <HStack w="full" alignItems={"start"}>
                <Image
                    src={`/images/buildings/${name.toLowerCase()}.png`}
                    border="4px solid"
                    borderColor="orange"
                    mb="2"
                    borderStyle={"inset"}
                    maxWidth={"120px"}
                />
                <Stack>
                    {name === BuildingType.COMMAND_CENTER && (
                        <Text color="green.100" fontWeight={"bold"}>
                            Unlocks specializations
                        </Text>
                    )}
                    {effects && <ModList value={effects} />}
                </Stack>
            </HStack>
            {buildingInfo?.unique && (
                <Text fontSize="sm" color="orange.400">
                    Unique building, undestructible.
                </Text>
            )}
        </Stack>
    )
}

export default BuildModal
