import { Box, Heading, List, ListItem } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { FaCheck } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import {Button} from "@/components/ui/button"
import { usePlayer } from "../hooks/query"

const PremiumPage: FunctionComponent = () => {
    const player = usePlayer()

    const navigate = useNavigate()

    return (
        <Box p="4">
            <Heading as="h1">Become Premium</Heading>

            <Heading as="h2" size="md" my="4">
                Why ?
            </Heading>

            <List.Root>
                <List.Item>
                    <List.Indicator color="green.500">
                        <FaCheck />
                    </List.Indicator>
                    Create a group to play with your friends
                </List.Item>
                <List.Item>
                    <List.Indicator color="green.500">
                        <FaCheck />
                    </List.Indicator>
                    Sleep well : 12 offline hours instead of 8
                </List.Item>
                <List.Item>
                    <List.Indicator color="green.500">
                        <FaCheck />
                    </List.Indicator>
                    Your votes counts twice
                </List.Item>
                <List.Item>
                    <List.Indicator color="green.500">
                        <FaCheck />
                    </List.Indicator>
                    Special color in chat and all characters allowed in username
                </List.Item>
                <List.Item>
                    <List.Indicator color="green.500">
                        <FaCheck />
                    </List.Indicator>
                    ... and more to come
                </List.Item>
            </List.Root>

            <Heading as="h2" size="md" my="4">
                How ?
            </Heading>

            <List.Root>
                <List.Item>
                    <List.Indicator color="green.500">
                        <FaCheck />
                    </List.Indicator>
                    Click on the button below
                </List.Item>
                <List.Item>
                    <List.Indicator color="green.500">
                        <FaCheck />
                    </List.Indicator>
                    Pay 10€
                </List.Item>
                <List.Item>
                    <List.Indicator color="green.500">
                        <FaCheck />
                    </List.Indicator>
                    Enjoy your premium features
                </List.Item>
            </List.Root>

            <Button mt="8" onClick={() => navigate("/checkout")}>Become Premium</Button>
        </Box>
    )
}

export default PremiumPage
