import { defineRecipe } from "@chakra-ui/react";

export const linkRecipe = defineRecipe({
    className: "chakra-link",
    base: {
        display: "inline-flex",
        alignItems: "center",
        outline: "none",
        gap: "1.5",
        cursor: "pointer",
        borderRadius: "l1",
        focusRing: "outside",
        _hover: {
            color: "red.600",
        },
        color: "orange.100"
    },

    variants: {
        variant: {
            underline: {
                textDecoration: "underline",
                textUnderlineOffset: "3px",
                // textDecorationColor: "currentColor/20",
                _hover: {
                    color: "red.600",
                    // textDecorationColor: "currentColor/20",
                }
            },
            plain: {
                color: "red.600",
                _hover: {
                    textDecoration: "underline",
                    textUnderlineOffset: "3px",
                    // textDecorationColor: "currentColor/20",
                    color: "red.600",
                },
            },
            transparent: {
                textDecoration: "none",
                color: "inherit",
            }
        },
    },

    defaultVariants: {
        variant: "underline",
    },
})