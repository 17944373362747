import { Box, HStack, Heading, SimpleGrid, Stack, Table, Text } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { useFaction, useGameId } from "../../hooks/query"
import { Role } from "../../types"
import { getEntries } from "../../utils"
import Panel from "../common/Panel"
import { default as RoleComponent } from "../common/Role"
import Player from "./Player"
import RoleSelect from "./RoleSelect"

const Players: FunctionComponent = () => {
    const gameId = useGameId()
    const data = useFaction()

    if (!data) {
        return null
    }

    // count the number of players in each role
    const roleCounts: { [key in Role]?: number } = {}
    const specCounts: { [key: string]: number } = {}
    data.details?.members?.forEach((member) => {
        if (!roleCounts[member.role]) {
            roleCounts[member.role] = 0
        }
        roleCounts[member.role]!++
        if (!specCounts[member.spec]) {
            specCounts[member.spec] = 0
        }
        specCounts[member.spec]!++
    })
    // sort roles by count
    Object.entries(roleCounts).sort((a, b) => b[1] - a[1])
    Object.entries(specCounts).sort((a, b) => b[1] - a[1])

    // sort members by name
    data.details?.members?.sort((a, b) => b.hq_level - a.hq_level)
    console.log(specCounts)

    return (
        <SimpleGrid columns={[1, 2]}>
            <Panel>
                <RoleSelect />
                <Stack mt="4">
                    <Heading as="h3" size="md">
                        Your faction roles
                    </Heading>
                    {getEntries(roleCounts).map(([role, count]) => (
                        <HStack key={role}>
                            <RoleComponent name={role} fontSize="sm" pr="2" />
                            <Text> : {count}</Text>
                        </HStack>
                    ))}
                    <Heading as="h3" size="md">
                        Your faction specializations
                    </Heading>
                    {getEntries(specCounts).map(([spec, count]) => (
                        <HStack key={spec}>
                            <Text>{spec === 'null' ? "No specialization" : spec} : {count}</Text>
                        </HStack>
                    ))}
                </Stack>
            </Panel>
            <Panel>
                <Heading as="h3" size="md" mb="2">
                    Players
                </Heading>
                <Box overflow="auto" maxHeight="500px">
                    <Table.Root size="sm" overflow="auto" bg="orange.700" border="2px solid" borderColor="orange.800">
                        <Table.Header>
                            <Table.Row>
                                <Table.ColumnHeader bgColor="orange.700" color="orange.100">Name</Table.ColumnHeader>
                                <Table.ColumnHeader bgColor="orange.700" color="orange.100">HQ level</Table.ColumnHeader>
                                <Table.ColumnHeader bgColor="orange.700" color="orange.100">Role</Table.ColumnHeader>
                                <Table.ColumnHeader bgColor="orange.700" color="orange.100">Spec</Table.ColumnHeader>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {data.details?.members?.map((member) => (
                                <Player key={member.id} {...member} />
                            ))}
                        </Table.Body>
                    </Table.Root>
                </Box>
            </Panel>
        </SimpleGrid>
    )
}

export default Players
